import {createSelector} from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import groupBy from 'lodash/groupBy';
import flow from 'lodash/flow';
import partialRight from 'lodash/partialRight';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import intersection from "lodash/intersection";
import gettext from 'airborne/gettext';
import moment from 'moment';

import {
    getCheckoutFareGroup, getOriginDestinations, getProviders,
    getSegments,
    getSelectedFlightPrice, getSeparatedTicketsCheckoutFareGroups, getSeparatedTicketsFlightPrices,
    isFlightPriceLoading,
    isSelectedFlightPriceAvailable,
} from 'airborne/store/modules/search_air/selectors';
import {matchConditions} from 'airborne/checkout2/helpers/crd';
import {getSeatSelectionPrice} from './seatSelection';
import {getBookingExtraActualTotal} from './bookingExtra';
import {
    GENDER_CHOICES,
    getAllServiceGroupKeys,
    hasCorrectTspmEmail,
} from 'airborne/air/checkout/helpers/checkoutForm';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {isAirExchangeFlow} from 'airborne/store/modules/exchange_air/selectors';
import settings from 'airborne/settings';
import isObject from 'lodash/isObject';
import {getFareBasisCodes} from 'airborne/air/checkout/helpers/brandedFares';
import {isAirSeparatedTicketsMode} from "airborne/store/modules/pricing_air/selectors";
import {getTicketIndexes} from "airborne/air/pricing/helpers/separatedTickets";
import {extractErrorMessage} from "airborne/common/errors";

function getCheckoutDataContainer(state) {
    return state.airBooking.checkoutData;
}

export function getNoTspmAccess(state) {
    return getCheckoutDataContainer(state).noTspmAccess;
}

export function getCheckoutData(state, ticketIndex = 0) {
    return getCheckoutDataContainer(state).data[ticketIndex];
}

export function getSeparatedTicketsCheckoutData(state) {
    return [getCheckoutData(state, 0), getCheckoutData(state, 1)];
}

export function getSeparatedFaresBooleanAttribute(state, attribute) {
    const [checkoutData1, checkoutData2] = getSeparatedTicketsCheckoutData(state);
    return checkoutData1[attribute] || checkoutData2[attribute];
}

export function getCheckoutDataBooleanAttribute(state, attribute) {
    if (isAirSeparatedTicketsMode(state)) {
        return getSeparatedFaresBooleanAttribute(state, attribute);
    }
    return getCheckoutData(state)[attribute];
}

export function getTspmCards(state) {
    return getCheckoutData(state).creditCards;
}

export function getContentRestrictions(state) {
    return getCheckoutData(state).contentRestrictions;
}

export function getPaymentOptions(state) {
    return getCheckoutData(state).payment_options;
}

export function isCheckoutDataLoading(state) {
    return getCheckoutDataContainer(state).loading;
}

export function getCurrentStep(state) {
    return state.airBooking.step;
}

export function getCheckoutForm(state) {
    return state.airBooking.checkoutForm;
}

export function getDefaultAddress(state) {
    const defaultAddress = getCheckoutData(state).default_billing_address;
    return isEmpty(defaultAddress) ? null : defaultAddress;
}

export function isTSASupported(state) {
    return getCheckoutDataBooleanAttribute(state, 'tsa_supported');
}

export function isPassportRequiredForRedress(state) {
    return getCheckoutDataBooleanAttribute(state, 'is_passport_required_for_redress');
}

export function isPassportOrNationalIdRequired(state) {
    return getCheckoutDataBooleanAttribute(state, 'is_passport_or_national_id_required');
}

export function isIdCardExpirationDateRequired(state) {
    return getCheckoutDataBooleanAttribute(state, 'idcard_exp_date_required');
}

export function getSeparatedTicketsStatuses(state) {
    return state.airBooking.separatedTicketsStatus;
}

export function getCurrentTicket(state) {
    const currentTicket = state.airBooking.currentTicket;
    return currentTicket === null ? undefined : currentTicket;
}

export function isSeparatedTicketsFinalBooking(state) {
    return getCurrentTicket(state) === last(getTicketIndexes());
}

export function getBookingStatus(state, ticketIndex) {
    if (isNil(ticketIndex)) {
        return state.airBooking.status;
    }

    return getSeparatedTicketsStatuses(state)[ticketIndex];
}

export function isBookingConfirmed(state) {
    if (!isAirSeparatedTicketsMode(state)) {
        return getBookingStatus(state).confirmed;
    }

    const allBookingsFailed = getTicketIndexes().every(
        ticketIndex => getBookingStatus(state, ticketIndex).message
    );

    if (allBookingsFailed) {
        return false;
    }

    return getTicketIndexes().every(ticketIndex => {
        const bookingStatus = getBookingStatus(state, ticketIndex);
        return bookingStatus.confirmed || (bookingStatus.message && !bookingStatus.recoverable)
    });
}

export function isBookingPartiallyConfirmed(state) {
    if (!isAirSeparatedTicketsMode(state)) {
        return false;
    }

    return getTicketIndexes().every(ticketIndex => {
        const bookingStatus = getBookingStatus(state, ticketIndex);
        return bookingStatus.confirmed || bookingStatus.recoverable
    });
}

export function getBooktrackId(state, ticketIndex) {
    const {uuid, retry} = getBookingStatus(state, ticketIndex);
    return `${uuid}R${retry}`;
}

export function getBookingUid(state, ticketIndex) {
    return getBookingStatus(state, ticketIndex).bookingUid;
}

export function isBookingLoading(state) {
    if (!isAirSeparatedTicketsMode(state)) {
        return getBookingStatus(state).loading;
    }

    return getTicketIndexes().some(
        ticketIndex => getBookingStatus(state, ticketIndex).loading
    );
}

export function isBookingDisabled(state) {
    const {message, recoverable} = getBookingStatus(state);
    return Boolean(message && !recoverable || isFlightPriceLoading(state) || !isSelectedFlightPriceAvailable(state));
}

export const getBookErrors = createSelector(
    (state, ticketIndex) => getBookingStatus(state, ticketIndex).message,
    extractErrorMessage,
);

export const getFarePricingDetails = state => {
    const fareGroup = getCheckoutFareGroup(state);
    const isSeparatedTickets = isAirSeparatedTicketsMode(state);
    const separatedTicketsFareGroups = getSeparatedTicketsCheckoutFareGroups(state);
    const segments = getSegments(state);
    const priceClassCodes = segments.map(({classOfService}) => classOfService);
    return {
        price: Number(getTotalFarePrice(state)).toFixed(2),
        priceClassCodes,
        fareBasisCodes: isSeparatedTickets
            ? separatedTicketsFareGroups.map(fareGroup => getFareBasisCodes(fareGroup))
            : getFareBasisCodes(fareGroup)
    };
};

export const getBookErrorCode = (state, ticketIndex) => getBookingStatus(state, ticketIndex).errorCode;
export const getBookErrorExtra = (state, ticketIndex) => getBookingStatus(state, ticketIndex).extra;
export const isFareChanged = (state, ticketIndex) => {
    const extra = getBookErrorExtra(state, ticketIndex) || {};
    const {fareGroup} = extra;
    return isObject(fareGroup);
};
export const getInitialFareDetails = (state, ticketIndex) => getBookingStatus(state, ticketIndex).initialFareDetails;

export const getPassports = state => getCheckoutData(state).passports || [];
export const getIdCards = state => getCheckoutData(state).idCards || [];
export const getSSRCodes = state => getCheckoutData(state).ssrCodes || [];

export const getSelectedSSRCodes = state => {
    const ssrCodesSeparatedTickets = getCheckoutForm(state).value.ssrCodesSeparatedTickets || [];
    return ssrCodesSeparatedTickets.flatMap(({ssrCodes}) => ssrCodes);
}

export const getPlaceOfBirth = state => getCheckoutData(state).place_of_birth;
export const getDateOfBirth = state => getCheckoutData(state).date_of_birth;

export const getVpaCardPools = state => getCheckoutData(state).vpa_card_pools;

export const getSelectedOptionalServices = (state, ticketIndex) => {
    if (!isNil(ticketIndex)) {
        return getSelectedOptionalServicesByTicket(state, ticketIndex);
    }

    return getCheckoutForm(state).value['optional_services'] || [];
};

export const getSelectedOptionalServicesByTicket = (state, ticketIndex) => {
    const serviceGroups = getTicketOptionalServiceGroups(state, ticketIndex);
    const serviceGroupKeysByTicket = getAllServiceGroupKeys(serviceGroups);
    const selectedServicesKeys = getSelectedOptionalServices(state);

    return selectedServicesKeys.filter(serviceKey => serviceGroupKeysByTicket.includes(serviceKey));
}

export const getCheckoutSeparatedTicketsFields = (state, ticketIndex) => {
    return getCheckoutForm(state).value.separatedTicketsFields[ticketIndex];
}
export const getCheckoutFrequentFlyerNumbers = (state, ticketIndex) => {
    if (isNil(ticketIndex)) {
        return getCheckoutForm(state).value['ftNumbers'];
    }

    return getCheckoutSeparatedTicketsFields(state, ticketIndex).ftNumbers || [];
};

export const getMinPassportExpiryDate = createSelector(
    getOriginDestinations,
    originDestinations => {
        const arrivalDate = originDestinations.slice(-1)[0].segments.slice(-1)[0].arrival.datetime;
        return moment(arrivalDate.slice(0, 10), 'YYYY-MM-DD', true)
            .startOf('day')
            .add(1, 'M'); // adding 1 month because flight month shouldn't match with document expiration month
    }
);

export const getVisas = state => {
    const visas = getCheckoutData(state)['secure_flight']['visa_documents'];
    const minExpiryDate = getMinPassportExpiryDate(state);
    return visas.filter((visa) =>
        !visa['expiration_date'] || moment(visa['expiration_date'], 'YYYY-MM-DD').isAfter(minExpiryDate)
    );
};

const SERVICE_TITLES = {
    'BG': () => gettext('Extra Luggage'),
    'TS': () => gettext('Priority check-in at airport'),
};

const getOriginDestinationLabels = ({segments}) => ({
    from: segments[0].departure.airport.code,
    to: segments[segments.length - 1].arrival.airport.code
});

const getOriginDestinationKey = ({segments}) => {
    return segments.map(({segmentIdRef}) => segmentIdRef).join('|');
};

const getServiceGroupProps = (services, currency, originDestination, ticketIndex) => {
    return flow(
        partialRight(groupBy, 'attributes.group.code'),
        Object.entries,
        groups => groups.filter(([groupCode]) => Object.keys(SERVICE_TITLES).indexOf(groupCode) !== -1),
        groups => groups.map(([groupCode, options], index) => ({
            groupCode,
            title: SERVICE_TITLES[groupCode](),
            ...(originDestination ? getOriginDestinationLabels(originDestination) : {}),
            options: uniqBy(options, 'serviceKey'),
            currency,
            groupKey: originDestination ? getOriginDestinationKey(originDestination) : String(index),
            fareGroupLevel: !originDestination,
            ticketIndex
        })),
    )(services);
};

const extractServiceGroups = (fareGroup, ticketIndex) => {
    const {optionalServices, currency, originDestinations = []} = fareGroup || {};

    const originDestinationLevel = flatten(
        originDestinations.map(({optionalServices, ...rest}) => getServiceGroupProps(optionalServices, currency, rest, ticketIndex))
    );

    const fareGroupLevel = getServiceGroupProps(optionalServices, currency, null, ticketIndex);

    return [...fareGroupLevel, ...originDestinationLevel]
}

export const getOptionalServiceGroups = state => {
    if (isAirSeparatedTicketsMode(state)) {
        const fareGroups = getSeparatedTicketsFlightPrices(state);
        return fareGroups.flatMap((fareGroup, ticketIndex) => extractServiceGroups(fareGroup, ticketIndex));
    }

    const fareGroup = getCheckoutFareGroup(state);
    return extractServiceGroups(fareGroup);
};

export const getTicketOptionalServiceGroups = (state, ticketIndex) => {
    const fareGroup = getSeparatedTicketsFlightPrices(state)[ticketIndex];
    // flight price response for the second ticket isn't ready once a user navigates to checkout,
    // however, some components use this selector before pricing request is finished
    if (!fareGroup) return [];

    return extractServiceGroups(fareGroup);
}

export const isPhoneRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'phone_number_required');
};

export const isGenderRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'gender_required');
};

export const isDOBRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'date_of_birth_required');
};

export const isNationalityRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'is_nationality_required');
};

export const isCvvRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'cvv_required');
};

export const isBillingAddressRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'billing_address_required');
};

export const isCardholderNameRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'cardholder_name_required');
};

export const isPassportRequired = state => {
    return getCheckoutDataBooleanAttribute(state, 'passport_required');
};

export const getCheckoutSkip = state => {
    return state.airBooking.skip;
};

export const getCheckoutFieldsToSkip = state => {
    const skipFields = [...getCheckoutSkip(state).fields];

    if (!isPhoneRequired(state)) {
        skipFields.push('phone');
    }

    return skipFields;
};

export const getCheckoutScreensToSkip = state => {
    return getCheckoutSkip(state).screens;
};

export const isSkippedScreen = (state, screen) => {
    return getCheckoutScreensToSkip(state).includes(screen);
};

export const getTotalFarePrice = state => {
    const isSeparatedTickets = isAirSeparatedTicketsMode(state);

    if (isSeparatedTickets) {
        return getSeparatedTicketsTotalPrices(state)
            .reduce((accumulator, currentPrice) => accumulator + currentPrice, 0);
    }

    const fareGroup = getSelectedFlightPrice(state);
    const seatsPrice = getSeatSelectionPrice(state);
    const actualTotal = getBookingExtraActualTotal(state);
    const farePrice = fareGroup?.total;

    return actualTotal || (farePrice + seatsPrice);
};

export const getSeparatedTicketsTotalPrices = state => {
    const fareGroups = getSeparatedTicketsFlightPrices(state);

    return fareGroups.map((fareGroup, ticketIndex) => {
        const actualTotal = getBookingExtraActualTotal(state, ticketIndex);
        const farePrice = fareGroup?.total;
        const seatsPrice = getSeatSelectionPrice(state, ticketIndex);
        return actualTotal || (farePrice + seatsPrice);
    });
};

export const isGPOSRedirectEnabled = state => {
    return get(state, 'dest.options.air_enable_gpos_booking_redirect');
};

export const isOutOfPolicyFare = createSelector(
    getCheckoutFareGroup,
    (fareGroup) => {
        return Boolean(fareGroup?.outOfPolicyRules?.length);
    }
);

export const getAirCRD = createSelector(
    getCheckoutData,
    getPaymentOptions,
    isOutOfPolicyFare,
    (settings, payment, isOutOfPolicy) => {
        const crd = settings?.crd || [];
        return crd.filter(
            matchConditions.bind(null, {outOfPolicy: isOutOfPolicy}, payment[0], null)
        );
    }
);

export function isReadonlyEmail(state) {
    const providers = getProviders(state);
    const isExchangeFlow = isAirExchangeFlow(state);
    const {email} = getCheckoutData(state);

    if (isExchangeFlow && email) {
        return true;
    }

    if (providers.some(provider => provider === 'travelfusion')) {
        return false;
    }

    return hasCorrectTspmEmail(state);
}

export function isReadOnlyName(state) {
    const pnrProfile = getPnrProfile(state, 0);
    const providers = getProviders(state);
    const isExchangeFlow = isAirExchangeFlow(state);
    const isPNR = Boolean(pnrProfile.pnr);
    const isGDS = providers.every(provider => settings.GDS_LIKE_PROVIDERS.includes(provider));

    return isPNR && (isGDS || isExchangeFlow);
}

export function getCanRequestMeal(state, ticketIndex) {
    return getCheckoutData(state, ticketIndex)['can_request_meal'];
}

export function getIsFfNumbersAvailable(state, ticketIndex) {
    const fareGroup = ticketIndex
        ? getSeparatedTicketsFlightPrices(state)[ticketIndex]
        : getCheckoutFareGroup(state);

    const {provider} = fareGroup || {};

    return provider !== 'travelfusion';
}

function getSupportedGenders(state) {
    const separatedTicketsMode = isAirSeparatedTicketsMode(state);

    if (separatedTicketsMode) {
        const [checkoutData1, checkoutData2] = getSeparatedTicketsCheckoutData(state);
        return intersection(checkoutData1['genders_supported'], checkoutData2['genders_supported']);
    }

    return getCheckoutData(state)['genders_supported'];
}

export function getSupportedGendersChoices(state) {
    const supportedGenders = getSupportedGenders(state);

    return supportedGenders.map(gender => (
        [gender, GENDER_CHOICES[gender]]
    ));
}
