import {createSelector} from 'reselect';
import {getRate} from 'airborne/store/modules/search_cars/selectors';
import {checkoutForm} from 'airborne/cars/checkout/helpers/checkoutForm';
import {matchConditions} from 'airborne/checkout2/helpers/crd';


export function getCarBooking(state) {
    return state.carBooking;
}

export function getSelectedRateId(state) {
    return getCarBooking(state).rateId;
}

export function getSelectedRate(state) {
    const rateId = getSelectedRateId(state);
    const rate = getRate(state, rateId);
    return rate;
}

export function getSelectedRateKey(state) {
    return getSelectedRate(state)['rateKey'];
}

export function getCheckoutData(state) {
    return state.carBooking['checkoutData']['data'];
}

export const getCarsCrd = createSelector(
    checkoutForm,
    getSelectedRate,
    getCheckoutData,
    (form, rate, settings) => {
        const crd = settings?.crd || [];
        return crd.filter(
            matchConditions.bind(null, rate, form.value['paid_by'], form.value['evoucher'])
        );
    }
);
