import moment from 'moment';

import {register} from 'airborne/types';
import {formatUrl} from 'midoffice/helpers/url';
import {dropEmpty} from 'midoffice/helpers/urlParams';

import {parse as parseRate} from 'airborne/cars/search/types';
import {serializeSearchDateRange} from 'midoffice/air-bookings/types';

function serializeValue(value) {
    return value.map((part)=> part.value);
}

function optional(fn, value, ...args) {
    return value ? fn(value, ...args) : null;
}

function fetchCollection() {
    return ['POST', formatUrl('/latest/cars/retrieve_reservations_list')];
}

function fetchEntry(type, id) {
    return `/latest/cars/get_reservation_details/?booking_uid=${id}&system=aft`;
}

function serialize(data) {
    const {page, 'page_size': pageSize, ordering=null, ...query} = data;
    const offset = ((page - 1) * pageSize);
    const {
        search=null,
        'created_at_min': createdMin,
        'created_at_max': createdMax,
        'pickup_datetime': pickup,
        'dropoff_datetime': dropoff,
        'entity': entity,
        'entity_aft': entityAft,
        ...filters
    } = query;
    return dropEmpty({
        limit: pageSize,
        offset,
        ordering,
        filters: dropEmpty({
            ...filters,
            'company': entity || optional(serializeValue, entityAft),
            'created_at': (createdMin || createdMax) ? dropEmpty({min: createdMin, max: createdMax}) : null,
            'pickup_datetime': serializeSearchDateRange(pickup),
            'dropoff_datetime': serializeSearchDateRange(dropoff),
            term: search,
        }),
    });
}

function parse(data) {
    const booking = data.booking || data;
    return {
        ...booking,
        id: booking.uid,
        rate: {
            ...parseRate(booking.rate),
            freetext: booking.freetext,
        },
        'created_at': optional(moment, booking['created_at']),
        'cancelled_at': optional(moment, booking['cancelled_at']),
    };
}

register('carBookings', {parse, fetchCollection, fetchEntry, serialize});
