import auth from 'airborne/store/modules/login/reducers/auth';
import featureFlags from 'airborne/store/modules/featureFlags/reducer';
import userSettings from 'airborne/store/modules/user_settings/reducers/userSettings';
import hash from 'midoffice/data/store/hash';
import tree from 'midoffice/data/store/tree';
import reports from 'midoffice/data/store/reports';
import settings from 'midoffice/data/store/settings';
import inheritance from 'midoffice/data/store/inheritance';
import hashUpload from 'midoffice/data/store/hashUpload';
import bulkUpload from 'midoffice/data/store/bulkUpload';
import alert from 'midoffice/data/store/alert';
import confirm from 'midoffice/data/store/confirm';
import modal from 'midoffice/data/store/modals';
import loader from 'midoffice/data/store/loader';
import session from 'midoffice/data/store/session';
import progress from 'midoffice/data/store/progress';
import queueChecker from 'midoffice/data/store/queueChecker';
import reportLoading from 'midoffice/data/store/reportLoading';
import pnrTool from 'midoffice/data/store/pnrTool';
import form from 'airborne/bookings2/reducers/form';
import externalBooking from 'midoffice/data/store/externalBooking';
import bookingModification from 'midoffice/bookings/modify/reducer';
import confermaFaxes from 'midoffice/bookings/conferma/reducer';
import inbox from 'airborne/store/modules/header/reducers/inbox';
import {freshBookings} from 'midoffice/store/modules/freshBookings';
import externalExtra from 'midoffice/store/modules/externalExtra/reducer';
import updatePaymentInfo from 'airborne/store/modules/update_payment/reducers';
import {bedTypes} from 'midoffice/store/modules/bedTypes';
import {providerCredentials} from 'midoffice/store/modules/providerCredentials';
import actionableCounteroffer from 'airborne/store/modules/counteroffer/reducers';
import actionableBooking from 'airborne/store/modules/actionable_booking/reducers';
import actionableCounter from 'airborne/store/modules/actionable_booking/reducers/actionableCounter';
import termsConditions from 'airborne/termsConditions/reducers/terms';
import fileCheckProcessing from 'midoffice/data/store/filecheckProcessing';
import tdlFeatures from 'airborne/store/modules/tdl_features/reducers';
import users from 'midoffice/store/modules/users/reducers';
import navigationShortcuts from 'airborne/store/modules/navigation_shortcuts/reducers';
import maintenances from 'airborne/store/modules/maintenance/reducers';

function atEndpoint(endpoint, reducer) {
    return [
        endpoint,
        function(state, action) {
            if (!state || action.endpoint === endpoint) {
                return reducer(state, action);
            }

            return null;
        }
    ];
}

const storeReducers = [
    ['auth', auth],
    atEndpoint('login', userSettings),
    atEndpoint('forgotPassword', userSettings),
    atEndpoint('terms', userSettings),
    atEndpoint('resetPassword', userSettings),
    atEndpoint('company_dependencies', hash),
    atEndpoint('agency_dependencies', hash),
    atEndpoint('region_dependencies', hash),
    atEndpoint('office_dependencies', hash),
    atEndpoint('tspm_history', hash),
    atEndpoint('companies', tree),
    atEndpoint('agencies', tree),
    atEndpoint('reports', reports),
    atEndpoint('settings', settings),
    atEndpoint('inheritance', inheritance),
    atEndpoint('campaigns', hash), // ad collections have silly names
    atEndpoint('posts', hashUpload), // to avoid ad blocker
    ['bulkUpload', bulkUpload],
    ['fileCheckProcessing', fileCheckProcessing],
    ['alert', alert],
    ['confirm', confirm],
    ['modal', modal],
    ['loader', loader],
    atEndpoint('session', session),
    atEndpoint('tspm_progress', progress),
    atEndpoint('regions', hash),
    atEndpoint('countryCodes', hash),
    atEndpoint('languages', hash),
    atEndpoint('adPlacements', hash),
    atEndpoint('check_credentials', queueChecker),
    atEndpoint('reportLoading', reportLoading),
    atEndpoint('pnr-tool', pnrTool),
    atEndpoint('auditlogs', hash),
    atEndpoint('usersAuditlogs', hash),
    atEndpoint('usersAuditlogFilters', form),
    atEndpoint('preferences', userSettings),
    atEndpoint('changePassword', userSettings),
    atEndpoint('auditlogFilters', form),
    atEndpoint('externalBooking', externalBooking),
    atEndpoint('airBookings', hash),
    atEndpoint('airBookingFilters', form),
    atEndpoint('airBookingModification', bookingModification),
    atEndpoint('externalBookings', hash),
    atEndpoint('externalBookingFilters', form),
    ['externalExtra', externalExtra],
    atEndpoint('bookings', hash),
    atEndpoint('bookingFilters', form),
    atEndpoint('bookingModification', bookingModification),
    atEndpoint('carBookings', hash),
    atEndpoint('carBookingFilters', form),
    atEndpoint('carBookingModification', bookingModification),
    atEndpoint('aftMessaging', hash),
    atEndpoint('confermaFaxes', confermaFaxes),
    atEndpoint('flags', hash),
    atEndpoint('inbox', inbox),
    ['users', users],
    ['freshBookings', freshBookings],
    ['maintenances', maintenances],
    ['updatePaymentInfo', updatePaymentInfo],
    ['bedTypes', bedTypes],
    ['providerCredentials', providerCredentials],
    ['actionableCounteroffer', actionableCounteroffer],
    ['actionableBooking', actionableBooking],
    ['actionableCounter', actionableCounter],
    ['termsConditions', termsConditions],
    ['tdlFeatures', tdlFeatures],
    ['featureFlags', featureFlags],
    ['navigationShortcuts', navigationShortcuts],
];
function reduceStores(reducers) {
    return function(state, action) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, func] of reducers) {
            let patch = func(state[key], action);
            if (patch) {
                state = {...state, [key]: patch};
            }
        }

        return state;
    };
}

export default reduceStores(storeReducers);

export function partlyReducers(...keys) {
    const filteredReducers = storeReducers.filter(([key]) => keys.includes(key));
    return reduceStores(filteredReducers);
}
