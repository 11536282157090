import {gettext} from 'airborne/gettext';
import {parseRecentAirAutocomplete} from './recentAir';
import {formatUrl} from 'midoffice/helpers/url';
import StatelessAutocomplete from "midoffice/data/StatelessAutocomplete";
import settings from "airborne/settings";
import capitalize from "lodash/capitalize";

export const TRAVELPORT_GDS = ['apollo', 'galileo'];

const ICONS = {
    'a': 'airplane',
    'ar': 'compass',
    'b': 'bus',
    'c': 'building',
    'cl': 'home',
    'h': 'hotel',
    'l': 'landmark',
    't': 'train',
    'p': 'boat',
};
function iconForType(type) {
    return ICONS[type] || null;
}

export function parseRecentAir({'air_recent_searches': list}) {
    return parseRecentAirAutocomplete(list);
}

export function nopAborted({request, status}) {
    if (request.isAborted) {
        throw new Object({ignore: true});
    }

    if (status === 401) {
        setTimeout(() => {
            location.assign(formatUrl('/accounts/login/', {next: location.pathname + location.search}));
        }, 1000);

        return gettext('You do not have permission to perform this action');
    }
    return gettext('Server error');
}

export function getDestinationQueryAutocompleteOption(query, items) {
    if (!items?.length) {
        return null;
    }

    const matchedItems = items.filter(item => item.iataCode === query.toUpperCase());
    const airport = matchedItems.find(item => item.type === 'a');

    if (airport) {
        return airport;
    }

    return matchedItems[0];
}

export const airlinesAutocomplete = {
    ...StatelessAutocomplete,
    async query(query, ...rest) {
        const results = await StatelessAutocomplete.query(query, ...rest);

        const isAirlineCodeQuery = query.length === 2;

        if (isAirlineCodeQuery) {
            // Bring matched airline code to the top of the list
            return results.sort((a, b) => {
                const airlineCode = query.toUpperCase();
                if (a.value === airlineCode) {
                    return -1;
                }
                if (b.value === airlineCode) {
                    return 1;
                }

                return 0;
            });
        }

        return results;
    },
};

function parseCompany(item) {
    const system = settings.SUB_PARTNER;
    const {
        id: value,
        title: label,
        smid,
        'parent_title': parentTitle,
        'tspm_entity_id': tspmId,
        'obt_client_id': obtClientId,
        'sap_account_number': sapAccountNumber,
        'gcn_id': gcnId,
        'primary_gds': primaryGds,
        'agency_type_name': agencyType,
        'country_of_origin_name': countryName,

    } = item;
    const gdsName = capitalize(primaryGds || item[`${system}.primary_gds`]);
    const gdsOid = item['gds_oid'] || item[`${system}.gds_oid`];

    return {
        value,
        label,
        parentLabel: parentTitle,
        tspmId,
        gdsName,
        gdsOid,
        obtClientId,
        gcnId,
        sapAccountNumber,
        agencyType,
        countryName,
        smid,
    };
}

export function parseCompanyList(data) {
    return data.map(parseCompany);
}
