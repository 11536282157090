import find from 'lodash/find';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';

import normalize from 'airborne/cars/homepage/helpers/normalize';
import settings from 'airborne/settings';


export function prepareChoices(mapping) {
    return sortBy(Object.entries(mapping), [0]).map(
        ([key, value]) => [key, `${key} - ${value}`]
    );
}

export function getVendorsChoices(gds) {
    if ((gds) === 'all') {
        return prepareChoices(settings.CARS_ALL_VENDORS);
    }
    const vendorsChoices = Object.assign({}, ...Object.keys(settings.CARS_VENDORS).map(
        (provider) => ({
            [provider]: prepareChoices(settings.CARS_VENDORS[provider])
        })
    ));
    return vendorsChoices[gds];
}

export function getVendors(gds) {
    if (gds === 'all') {
        return settings.CARS_ALL_VENDORS;
    }
    return settings.CARS_VENDORS?.[gds];
}


export function getLabel(description, number) {
    return [description, number].filter(x=>x).join(' - ');
}

export function getNegotiatedChoices(state, field) {
    const companyCodes = state[field];
    const defaultField = field === 'cars_it_numbers' ? 'is_it_default' : 'is_default';
    const codeField = field === 'cars_it_numbers' ? 'it_number' : 'cd_number';
    const custom = ['custom', '', false];
    const choicesList = (companyCodes || []).reduce((acc, {vendor, [codeField]: number, 'is_bcd_code': isBcdCode, description, [defaultField]: isDefault})=> ({
        ...acc,
        [vendor]: (
            isDefault
                ? [[number, getLabel(description, number), isBcdCode], ...(acc[vendor] || [])]
                : [...(acc[vendor] || []), [number, getLabel(description, number), isBcdCode]]),
    }), {});
    forEach(choicesList, (value, vendor) => {
        choicesList[vendor].push(custom);
    });
    return choicesList;
}

export function getNegotiatedIdChoices(companyCodes, idNumbersValue) {
    const customID = ['custom', '', false];
    const choicesList = (companyCodes || []).filter(negotiated => Boolean(negotiated['company_id_number'])).reduce((acc, {vendor, 'company_id_number': number, description, 'is_company_id_default': isDefault})=> ({
        ...acc,
        [vendor]: isDefault ? [[number, getLabel(description, number)], ...(acc[vendor] || [])] : [...(acc[vendor] || []), [number, getLabel(description, number)]],
    }), {});
    forEach(choicesList, (value, vendor) => {
        const {customIdInput} = find(idNumbersValue, {vendor}) || {};
        customIdInput
            ? choicesList[vendor].push(['custom', customIdInput, false])
            : choicesList[vendor].push(customID);
    });
    return choicesList;
}
export const getNegotiatedIdCdMatrix = (companyCodes) => normalize(companyCodes)
    .filter(({companyIdNumber}) => Boolean(companyIdNumber))
    .map(({cdNumber, companyIdNumber, isCompanyIdDefault}) => ({
        cdNumber,
        idNumber: companyIdNumber,
        isDefault: isCompanyIdDefault || false,
    }));
