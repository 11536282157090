import last from 'lodash/last';
import {getFlightArrivalCode, getFlightDepartureCode} from "airborne/air/availability/helpers/flightOptions";

export function getTicketIndexes() {
    return [0, 1];
}

export function getDepartureArrivalCodes(flightOption) {
    const departureCode = getFlightDepartureCode(flightOption);
    const arrivalCode = getFlightArrivalCode(flightOption);
    return `${departureCode}-${arrivalCode}`;
}

export function getDepartureArrivalCodesFromDest(originDestinations) {
    const [firstOriginDestination] = originDestinations;
    const departureCode = getFlightDepartureCode(firstOriginDestination);
    const arrivalCode = getFlightArrivalCode(last(originDestinations));
    return `${departureCode}-${arrivalCode}`;
}

export function getDepartureArrival(fare) {
    const departure = fare.originDestinations[0].segments[0].departure;
    const arrival = last(fare.originDestinations[0].segments).arrival;
    return [departure, arrival];
}
