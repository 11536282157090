'process i18n';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FilterMenu from 'midoffice/components/FilterMenu';
import RowWrapper from 'midoffice/components/RowWrapper';

import Tags from 'midoffice/newforms/widgets/Tags';
import DateInput from 'midoffice/newforms/widgets/DateInput';
import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';

import {injectFormContext} from 'midoffice/newforms/decorators';
import {AdminAutocomplete, AffectedUserAutocomplete} from 'midoffice/data/UsersAuditlogsAutocompletes';
import FiltersSchema from './FiltersSchema';
import {changeFiltersForm, searchLogs} from './actions';


const USERTYPE = [
    ['agent', 'Agents'],
    ['admin', 'Admins'],
];

@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
    };

    adminAutocomplete = new AdminAutocomplete();
    affectedUserAutocomplete = new AffectedUserAutocomplete();

    handleClear = (event) => {
        event.preventDefault();
        this.props.onChange({value: {}});
    };

    render () {
        const extra = {
            labelCol: null,
            inputCol: null,
        };

        return (<form className="form-vertical">
            <Row>
                <Col xs={6} >
                    <DateInput.Field
                        label="Created From"
                        name="timestamp_min"
                        time={true}
                        className="form-group--datetime"
                        inputFormat={settings.USER.date_format_str}
                        plain
                        {...extra} />
                    <DateInput.Field
                        label="Created To"
                        name="timestamp_max"
                        time={true}
                        className="form-group--datetime"
                        inputFormat={settings.USER.date_format_str}
                        plain
                        {...extra} />
                    <Tags.Field
                        name="user"
                        label="User (group)"
                        placeholder="Enter one or more users"
                        autocompleteSource={this.adminAutocomplete}
                        {...extra} />
                </Col>
                <Col xs={6}>
                    <Tags.Field
                        name="affected_user"
                        label="Affected Email/Username"
                        placeholder="Enter one or more email/username"
                        autocompleteSource={this.affectedUserAutocomplete}
                        {...extra} />
                    <MultiCheckbox.Field
                        name="affected_user_type"
                        label="Affected User Type"
                        choices={USERTYPE}
                        {...extra} />
                </Col>
            </Row>

            <div className="complex-dropdown__clear-lnk">
                <a onClick={this.handleClear} href="#">
                    <span className="complex-dropdown__clear-icon">&times;</span> Clear All Filters
                </a>
            </div>
        </form>);
    }
}

function mapStateProps({usersAuditlogFilters}) {
    return usersAuditlogFilters;
}

@connect(mapStateProps, {
    onChange: changeFiltersForm,
    onSearch: searchLogs,
})
export default class Filters extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            search: PropTypes.string,
        }),
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
    };

    handleChange = (fieldValue, name) => {
        const {value} = this.props;
        this.props.onChange({
            value: {...value, [name]: fieldValue}
        });
    };

    render() {
        const {search} = this.props.value || {};
        return (<RowWrapper condensed>
            <div className="pull-right">
                <FilterMenu search={search || null}
                    onSearch={this.props.onSearch}
                    onChange={this.handleChange}
                    placeholder={gettext('Search')}
                    narrow >
                    <Editor {...this.props} schema={FiltersSchema} />
                </FilterMenu>
            </div>
        </RowWrapper>);
    }
}
