/* jshint esnext:true */
'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import {gettext} from 'airborne/gettext';
import classnames from 'classnames';
import {Link} from 'react-router-dom';

export const AUDIT_LOGS_TYPES = [
    {id: 'companies-agencies', title: 'Companies/Agencies', url: '/audit-logs/entities/'},
    {id: 'users', title: 'Users', url: '/audit-logs/users/'},
];

class AuditLogsTab extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
    };

    render() {
        const {title, isActive, url} = this.props;
        const className = classnames('tabs-inner__item', {
            'tabs-inner__item--active': isActive,
        });

        return (
            <Link to={url} className={className}>
                <span  className="tabs-inner__item__title">{gettext(title)}</span>
            </Link>
        );
    }
}

export default class AuditLogsTabs extends React.Component {
    static propTypes = {
        selected: PropTypes.string.isRequired,
        logsTypes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })),
    };

    render() {
        const {selected, logsTypes} = this.props;

        return (
            <div className="tabs-inner tabs-inner--categories">
                <nav className="tabs-inner__controls">
                    {logsTypes.map((type) => (
                        <AuditLogsTab
                            key={type.id}
                            isActive={selected === type.id}
                            {...type}
                        />
                    ))}
                </nav>
            </div>
        );
    }
}
