import {dropEmpty} from 'midoffice/helpers/urlParams';
import pick from 'lodash/pick';

import 'airborne/cars/checkout/types';
import {getToStep, getStepAfter, getStepBefore} from 'airborne/cars/checkout/helpers/steps';
import {applyPatch} from 'midoffice/newforms/helpers';
import {
    CAR_BOOKING_MODIFICATION_FLOW,
    CAR_BOOKING_MODIFICATION_FLOW_QUIT
} from 'midoffice/car-bookings/modify/actionTypes';

const COLLECTION_KEY = 'collection';
const DELIVERY_KEY = 'delivery';

const collectionDeliveryFields = [
    'company_name',
    'city',
    'country_code',
    'state_code',
    'postal_code',
    'street_address',
    'phone',
];

const mapColDelFields = (key) => collectionDeliveryFields.map(field => `${key}_${field}`);

const KEEP_BETWEEN_CHECKOUTS_FIELDS = [
    'first_name',
    'last_name',
    'special_information',
    'email',
    'same_as_delivery',
    'withDelivery',
    'withCollection',
    ...mapColDelFields(COLLECTION_KEY),
    ...mapColDelFields(DELIVERY_KEY),
];

function resetValue(value) {
    return dropEmpty(pick(value, KEEP_BETWEEN_CHECKOUTS_FIELDS));
}

export default function carBooking(state, {type, rateId, step, checkoutData, uuid, message, recoverable, toChange, toRemove, crd}) {
    const toStep = getToStep();
    const stepBefore = getStepBefore();
    const stepAfter = getStepAfter();

    if (!state) {
        return {
            loading: false,
            modificationFlow: false,
            checkoutLoading: false,
            rateId: null,
            step: 'general',
            value: {},
            errors: null,
            uuid: null,
            retry: 0,
            message: null,
            checkoutData: {
                loading: false,
                data: null,
            },
            noTspmAccess: false,
        };
    }

    if (type === 'CARS_TO_CHECKOUT') {
        return {
            ...state,
            checkoutLoading: false,
            uuid,
            retry: 0,
            message: null,
            recoverable: null,
            step: 'general',
            value: resetValue(state.value),
        };
    }

    if (type === 'CARS_CHECKOUT_LOADING') {
        return {...state, checkoutLoading: true, rateId};
    }

    if (type === 'CARS_CHECKOUT_FAIL') {
        return {...state, checkoutLoading: false};
    }

    if (type === 'CARS_BOOKING_LOADING') {
        return {...state, loading: true, message: null};
    }

    if (type === 'CARS_BOOKING_FAIL') {
        return {...state, loading: false, message, recoverable, retry: state.retry + 1};
    }

    if (type === 'CARS_TO_CONFIRM') {
        return {...state, loading: false};
    }

    if (type === 'CARS_PATCH_BOOKING_FIELDS') {
        return {
            ...state,
            ...applyPatch({value: state.value, errors: state.errors, toChange, toRemove}),
        };
    }

    if (type === 'CARS_TO_PREV_CHECKOUT_STEP') {
        const prevStep = crd.length === 0 ? 'general' : state.step;
        return {...state, step: stepBefore(prevStep)};
    }

    if (type === 'CARS_TO_NEXT_CHECKOUT_STEP') {
        return {...state, step: stepAfter(state.step)};
    }

    if (type === 'CARS_TO_CHECKOUT_STEP') {
        return {...state, step: toStep(state.step, step)};
    }

    if (type === 'CARS_CHECKOUT_DATA_LOADING') {
        return {...state, checkoutData: {...state.checkoutData, loading: true}};
    }

    if (type === CAR_BOOKING_MODIFICATION_FLOW) {
        return {...state, modificationFlow: true};
    }
    if (type === CAR_BOOKING_MODIFICATION_FLOW_QUIT) {
        return {...state, modificationFlow: false};
    }


    if (type === 'CARS_CHECKOUT_DATA_LOADED') {
        return {...state, checkoutData: {data: checkoutData, loading: false}, noTspmAccess: false};
    }

    if (type === 'CARS_CHECKOUT_DATA_FAIL') {
        return {...state, checkoutData: {data: null, loading: false}, noTspmAccess: true};
    }

    if (type === 'TRAVELER_PROFILE_CHANGED' && state.modificationFlow) {
        return state;
    }

    if (type === 'TRAVELER_PROFILE_CHANGED' || type === 'LOADED_PNR' || type === 'NO_PNR') {
        return {
            ...state,
            checkoutData: {data: null, loading: false},
            value: {},
            erorrs: null,
        };
    }

    return state;
}
