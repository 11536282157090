import SentryAdapter from 'airborne/sentryAdapter';
import {batch} from 'react-redux';
import {STEPS} from 'airborne/air/exchange/Modification/types';
import {AIR_SEARCH_TYPES} from 'airborne/homepage2/types';
import * as actionTypes from '../actionTypes';

import {
    getAirBeforeExchangeCurrency,
    getAirExchangeBookingCompany,
    getAirExchangeBookingPNR,
} from 'airborne/store/modules/exchange_air/selectors';

import {
    formChangePNR,
    retrievePNR,
    discardPNR,
    selectCompany,
    noPNR
} from 'airborne/store/modules/homepage/actions/pnr';
import {airChangeSearchType, changeProduct} from 'airborne/store/modules/homepage/actions';
import {showBookingRecordsAir} from 'airborne/store/modules/header/actions/changeStep';

import {prefillAirExchangeForm} from './prefill';
import {resetAirExchangeForm} from 'airborne/store/modules/exchange_air/actions/form';
import {showModal, hideModal} from 'airborne/store/modules/header/actions/modal';
import {searchBookings as searchAirBookings} from 'midoffice/air-bookings/actions';
import {isBookingConfirmed} from 'airborne/store/modules/checkout_air/selectors';
import {apiError} from 'airborne/search2/helpers/apiError';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';
import {setCurrency} from 'airborne/store/modules/header/actions/i18n';

const openAirExchange = () => {
    return {
        type: actionTypes.AIR_EXCHANGE_OPEN,
    };
};

const closeAirExchange = () => {
    return {
        type: actionTypes.AIR_EXCHANGE_CLOSE
    };
};

const setAirExchangeLoading = (isLoading) => {
    return {
        type: actionTypes.AIR_EXCHANGE_SET_LOADING,
        payload: isLoading,
    };
};

const setCurrentBooking = (booking) => {
    return {
        type: actionTypes.SET_CURRENT_BOOKING,
        payload: {booking},
    };
};

const setBeforeExchangeCurrency = (currency) => {
    return {
        type: actionTypes.SET_BEFORE_EXCHANGE_CURRENCY,
        payload: currency
    };
};

const setCurrentStep = (step) => {
    return {
        type: actionTypes.SET_CURRENT_STEP,
        payload: {step},
    };
};

const resetAirExchange = () => (dispatch) => {
    dispatch(discardPNR());
    dispatch(resetAirExchangeForm());
};

export const goToExchangeForm = () => setCurrentStep(STEPS.FORM);
export const goToExchangeAvailability = () => setCurrentStep(STEPS.AVAILABILITY);
export const goToExchangeFareSearch = () => setCurrentStep(STEPS.FARE_SEARCH);
export const goToExchangePricing = () => setCurrentStep(STEPS.PRICING);
export const goToManualExchange = () => setCurrentStep(STEPS.MANUAL_EXCHANGE);

const retrieveExchangePNR = (booking, isExternalBooking) => async (dispatch) => {
    const gdsName = booking.gdsProfile?.gdsName;
    const provider = isExternalBooking ? gdsName : null;

    return await dispatch(retrievePNR(
        true,
        false,
        null,
        provider,
    ));
};

const setPnrForm = (company, pnr) => (dispatch) => {
    return dispatch(formChangePNR({value: {company, pnr}}));
};

export const startAirExchange = (booking, isExternalBooking = false) => async (dispatch, getState) => {
    try {
        batch(() => {
            dispatch(resetAirExchange());
            dispatch(setAirExchangeLoading(true));
            dispatch(changeProduct('air'));
            dispatch(setCurrentBooking(booking));
            dispatch(airChangeSearchType(AIR_SEARCH_TYPES.AVAILABILITY));
        });

        const company = getAirExchangeBookingCompany(getState());
        const pnr = getAirExchangeBookingPNR(getState());
        dispatch(setPnrForm(company, pnr));
        await dispatch(selectCompany(company));

        const beforeExchangeCurrency = getCurrency(getState());
        dispatch(setBeforeExchangeCurrency(beforeExchangeCurrency));
        dispatch(setCurrency(booking.confirmation.originalCurrency));

        await Promise.all([
            await dispatch(pnr ? retrieveExchangePNR(booking, isExternalBooking) : noPNR(company, 0)),
            await dispatch(prefillAirExchangeForm()),
        ]);

        dispatch(openAirExchange());
    }
    catch (response) {
        if (response instanceof Error) {
            SentryAdapter.captureException(response);
        }

        dispatch(showModal('error', {message: apiError(response)}));
    }
    finally {
        batch(() => {
            dispatch(setAirExchangeLoading(false));
            dispatch(showBookingRecordsAir());
        });
    }

};

export const setAirManualExchange = isManualExchange => ({
    type: actionTypes.AIR_SET_MANUAL_EXCHANGE,
    payload: isManualExchange,
});

export const cancelAirExchange = () => (dispatch, getState) => {
    const beforeExchangeCurrency = getAirBeforeExchangeCurrency(getState());
    batch(() => {
        dispatch(resetAirExchange());
        dispatch(closeAirExchange());
        dispatch(showBookingRecordsAir());
        dispatch(setCurrency(beforeExchangeCurrency));
        dispatch(setAirManualExchange(false));
        dispatch(setBeforeExchangeCurrency(null));
    });

    if (isBookingConfirmed(getState())) {
        dispatch(hideModal());
        dispatch(searchAirBookings());
    }
};
