import moment from 'moment';

import {register} from 'airborne/types';
import {formatUrl} from 'midoffice/helpers/url';
import {dropEmpty} from 'midoffice/helpers/urlParams';

function serialize({timestamp_min, timestamp_max, user, affected_user, ...params}) {  // eslint-disable-line camelcase
    return dropEmpty({
        ...params,
        user: (Array.isArray(user) && user.length)
            ? user.map(({value})=> value)
            : null,
        affected_user: (Array.isArray(affected_user) && affected_user.length)  // eslint-disable-line camelcase
            ? affected_user.map(({value})=> value)  // eslint-disable-line camelcase
            : null,
        timestamp_min: timestamp_min  // eslint-disable-line camelcase
            ? moment(timestamp_min).toISOString()  // eslint-disable-line camelcase
            : null,
        timestamp_max: timestamp_max  // eslint-disable-line camelcase
            ? moment(timestamp_max).toISOString()  // eslint-disable-line camelcase
            : null,
    });
}

function fetchCollection(endpoint, params) {
    return formatUrl('/api/audit-logs/users/', serialize(params));
}

register('usersAuditlogs', {fetchCollection});
