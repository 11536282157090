import difference from 'lodash/difference';
import uniqBy from 'lodash/uniqBy';

import location from 'airborne/location';
import StreamService from 'airborne/services/Stream';
import {parse} from 'airborne/store/config/types/inbox';
import {getMessages, getLatestTimestamp} from 'airborne/store/modules/header/selectors/inbox';
import api from 'midoffice/helpers/api';
import {formatUrl} from 'midoffice/helpers/url';


function close(messages, query) {
    if (!messages || !messages.length) {
        return;
    }
    const data = {'from_ts': getLatestTimestamp(messages), ...query};
    return api('POST', '/api/aft_messages/close/', {data});
}

const cache = new Map();

function stopTimers(keyArray) {
    keyArray.forEach(key => {
        clearTimeout(cache.get(key));
        cache.delete(key);
    });
}

const endpoint = 'inbox';
const MAX_SAFE_DELAY_VALUE = 2 ** 31 - 1;

function inboxRequest() {
    return function inboxRequestD(dispatch, getState) {
        const latest = getLatestTimestamp(getMessages(getState()));
        const headers = latest ? {'If-Modified-Since': latest} : {};

        return api('GET', '/api/aft_messages/inbox/', {headers})
            .then(messages => {
                if (!messages) {
                    const keysArray = [...cache.keys()];
                    stopTimers(keysArray);
                    return;
                }

                const parsedMessages = parse(messages);
                const needToShowFutureMessages = parsedMessages.some(({active}) => !active);

                if (!needToShowFutureMessages) {
                    const keysArray = [...cache.keys()];
                    stopTimers(keysArray);
                }

                if (needToShowFutureMessages) {
                    const futureMessages = parsedMessages.filter(({active}) => !active);

                    const objectsToStartTimers = futureMessages.map(({fromDate, whenNeedToShow}) => ({
                        timestamp: fromDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                        delay: Math.min(whenNeedToShow, MAX_SAFE_DELAY_VALUE),
                    }));

                    const futureDates = uniqBy(objectsToStartTimers, 'timestamp');
                    const cacheKeys = [...cache.keys()];
                    const dateKeysToStartTimers = futureDates.map(({timestamp}) => timestamp);
                    const keysToStopUnusedTimers = difference(cacheKeys, dateKeysToStartTimers);
                    stopTimers(keysToStopUnusedTimers);

                    futureDates.map(({timestamp, delay}) => {
                        const timerId = setTimeout(() => {
                            dispatch(inboxRequest());
                        }, delay);

                        if (cache.has(timestamp)) {
                            clearTimeout(cache.get(timestamp));
                        }
                        cache.set(timestamp, timerId);
                    });
                }
                dispatch({type: 'LOADED', data: parsedMessages, endpoint});
            })
            .catch((response)=> {
                if (response.status === 401) {
                    location.assign(formatUrl('/accounts/login/', {next: location.pathname + location.search}));
                    return response;
                }
            });
    };
};

function subscribeAftMessages(dispatch) {
    if (!StreamService.connected) {
        setTimeout(() => subscribeAftMessages(dispatch), 1000);
        return null;
    }

    StreamService.subscribeOn(
        'INBOX_MESSAGE',
        () => {
            dispatch(inboxRequest());
        }
    );
}

export function loadInbox() {
    return async function loadInboxD(dispatch) {
        try {
            await dispatch(inboxRequest());
        }
        catch (error) {
            // ignore
        }
        subscribeAftMessages(dispatch);
        return null;
    };
}

export function markRead(query) {
    return function markReadD(dispatch, getState) {
        dispatch({type: 'MARK_READ', endpoint, query});
        return close(
            getMessages(getState(), query),
            query
        );
    };
}
