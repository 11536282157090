import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import union from 'lodash/union';
import minBy from 'lodash/minBy';
import first from 'lodash/first';
import last from 'lodash/last';

import normalize from 'airborne/air/shared/helpers/normalize';
import {register} from 'airborne/types';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import {
    durationToMinutes,
    getCabins,
    getMinutesFromMidnight,
    getOperatingCarriers,
    getProviderType,
    FARE_TYPES,
    isLccFare,
} from './helpers';
import {PREFERRED_AIRLINES_CHOICES} from "airborne/air/fare_search/helpers/filters";
import {parseSeatMap} from 'airborne/air/fare_search/helpers/seatMap';
import systemData from 'airborne/systemData';
import intersection from 'lodash/intersection';

function getAllCarrierNames(segments) {
    return union.apply(null, segments.map(({carrier, operatingCarrier}) =>
        [carrier?.name, operatingCarrier?.name].filter(Boolean)
    ));
}

const getClassesOfService = segments => {
    const uniqByCabin = uniqBy(segments, 'cabin');
    const getCabin = cabin =>
        (settings.CABIN_CLASSES[cabin] || cabin || gettext('Unknown'));

    if (uniqByCabin.length === 1) {
        // "Economy (R, Y) Class"
        const classes = uniqBy(segments, 'classOfService')
            .map(s => s.classOfService || '?')
            .join(', ');
        return [`${getCabin(uniqByCabin[0].cabin)} (${classes})`];
    }

    // "Mixed Class" tooltip: "Economy (R), Business (Y)"
    return uniqByCabin.map(({cabin, classOfService}) =>
        `${getCabin(cabin)} (${classOfService || '?'})`);
};

export const getGroupFlightNumbers = ODs => uniq(ODs.reduce(
    (acc, {flightOptions}) => flightOptions.reduce(
        (acc, {flightNumbers}) => [...acc, ...flightNumbers], acc), []));

export const getFirstSegment = (flightOption) => {
    return flightOption.segments[0];
};
export const getLastSegment = (flightOption) => {
    const {segments} = flightOption;

    return segments[segments.length - 1];
};

export const isPreferredByKey = (segments, key) => segments?.some(segment => segment[key]);

const {BCD_PREFERRED, COMPANY_PREFERRED} = PREFERRED_AIRLINES_CHOICES;
const parseOriginDestinations = ODs => ODs.map(OD=> ({
    ...OD,
    flightOptions: OD.flightOptions
        // Sort flight options by departure time
        .sort((optionA, optionB) => {
            const {datetime: datetimeA} = getFirstSegment(optionA).departure;
            const {datetime: datetimeB} = getFirstSegment(optionB).departure;
            const minutesA = getMinutesFromMidnight(datetimeA);
            const minutesB = getMinutesFromMidnight(datetimeB);

            return minutesA - minutesB;
        })
        .map(option => ({
            ...option,
            departureMinutes: getMinutesFromMidnight(getFirstSegment(option).departure.datetime),
            arrivalMinutes: getMinutesFromMidnight(getLastSegment(option).arrival.datetime),
            durationMinutes: durationToMinutes(option.duration),
            classesOfService: getClassesOfService(option.segments),
            // uniq in carrier & operatingCarrier, used for filters
            airlines: getAllCarrierNames(option.segments),
            // uniq in cabin, used for filters
            cabins: getCabins(option.segments),
            // uniq in operatingCarrier only, used for icons
            operatingCarriers: getOperatingCarriers(option.segments),
            flightNumbers: uniqBy(option.segments, 'carrier.flightNumber')
                .map(({carrier}) => `${carrier.code}${carrier.flightNumber}`),
            [BCD_PREFERRED]: isPreferredByKey(option.segments, 'bcdPreferredAirline'),
            [COMPANY_PREFERRED]: isPreferredByKey(option.segments, 'preferredAirline'),
        }))
}));

export const getFareAirlines = (originDestinations) => {
    return uniq(originDestinations.reduce((acc, {flightOptions}) => {
        return [...acc, ...flightOptions.reduce((acc, {airlines}) => ([...acc, ...airlines]), [])];
    }, []));
};

const getFareAlliances = ({fareComponents = []}) => {
    const airAlliances = Object.entries(systemData.air.AIR_ALLIANCES);
    const fareGroupAirlineCodes = fareComponents.map(component => component.airlineCode);
    return airAlliances
        .filter(([, allianceAirlineCodes]) => intersection(allianceAirlineCodes, fareGroupAirlineCodes).length)
        .map(([alliance]) => alliance);
};

export function getFareTypes(fareGroup) {
    const defaultFareTypes = [FARE_TYPES.PUBLISHED];

    const {fareComponents} = fareGroup;
    const fareTypes = uniq(fareComponents?.reduce((acc, fareComponent) => {
        const {isNegotiated, isBcdNegotiated, isCompanyNegotiated} = fareComponent;
        if (!isNegotiated && !isLccFare(fareGroup)) {
            return [...acc, FARE_TYPES.PUBLISHED];
        }

        const fareTypes = [];
        if (isNegotiated) {
            fareTypes.push(FARE_TYPES.NEGOTIATED);
        }

        if (isBcdNegotiated) {
            fareTypes.push(FARE_TYPES.BCD_NEGOTIATED);
        }

        if (isCompanyNegotiated) {
            fareTypes.push(FARE_TYPES.COMPANY_NEGOTIATED);
        }

        return [...acc, ...fareTypes];
    }, []));

    return fareTypes.length ? fareTypes : defaultFareTypes;
}

export function getMaxDuration(originDestinations) {
    return Math.max(...originDestinations.map(({flightOptions}) =>
        minBy(flightOptions, 'durationMinutes')?.durationMinutes
    ));
}

export const parseFareGroups = fareGroup => {
    const group = normalize(fareGroup);
    const originDestinations = parseOriginDestinations(group.originDestinations);

    return {
        ...group,
        originDestinations,
        flightNumbers: getGroupFlightNumbers(originDestinations),
        withBaggage: Boolean(group.baggageAllowance &&
            (group.baggageAllowance.maxPieces || group.baggageAllowance.maxWeight)),
        maxDurationMinutes: getMaxDuration(originDestinations),
        providerType: getProviderType(group),
        airlines: getFareAirlines(originDestinations),
        alliances: getFareAlliances(group),
        fareTypes: getFareTypes(group),
    };
};


// OD stands for Origin Destinations
// There is a ticket with proposal to rename it all across the project: GG-25064
const getDeparture = OD => {
    const {segments} = OD;
    const {departure} = first(segments);
    return departure;
};

const getArrival = OD => {
    const {segments} = OD;
    const {arrival} = last(segments);
    return arrival;
};

export const getPosition = (ODs, index) => {
    if (ODs.length > 2) {
        return '';
    }

    if (index === 0) {
        return 'outbound';
    }

    return 'inbound';
};

export const parseFlightPrice = fareGroup => {
    const group = normalize(fareGroup);
    const {fareComponents = []} = group;

    const {priceClassName = null} = fareComponents
        .find(({priceClassName}) => Boolean(priceClassName)) || {};
    const {priceClassDescription = null} = fareComponents
        .find(({priceClassDescription}) => Boolean(priceClassDescription)) || {};

    return {
        ...group,
        priceClassName,
        priceClassDescription,
        originDestinations: group.originDestinations.map(({baggageAllowances, ...OD}, index, ODs) => ({
            ...OD,
            baggageAllowance: baggageAllowances[0].baggageAllowance,
            classesOfService: getClassesOfService(OD.segments),
            departure: getDeparture(OD),
            arrival: getArrival(OD),
            position: getPosition(ODs, index),
        })),
    };
};

register('fareGroups', {parse: parseFareGroups});
register('flightPrice', {parse: parseFlightPrice});
register('seatMap', {parse: parseSeatMap});
