export function parse(data) {
    const railDeutscheBahn = data['rail:suppliers:deutschebahn'] || {};
    return {
        'rail_deutsche_bahn:enabled': railDeutscheBahn['enabled'],
        'rail_deutsche_bahn:bmis': railDeutscheBahn['bmis'] || null,
        'rail_deutsche_bahn:uses_explicit_config': railDeutscheBahn['uses_explicit_config'] || false,
    };
};

export function serialize(data) {
    return {
        'rail:suppliers:deutschebahn': {
            'enabled': data['rail_deutsche_bahn:enabled'],
            'bmis': data['rail_deutsche_bahn:bmis'],
            'uses_explicit_config': data['rail_deutsche_bahn:uses_explicit_config'],
        }
    };
}
