/* global RELEASE */
import settings from 'airborne/settings';
import React from 'react';
import gettext from 'airborne/gettext';
import {Link} from 'react-router-dom';

function versionNumber(release='') {
    const [,major='',minor='0'] = release.split('.');
    return `${major}.${minor}`;
}

class Footer extends React.Component {
    render() {
        return (
            <div>
                <div className="footer-placeholder" />
                <footer className="page-footer">
                    <div className="container">
                        <div className="footer-top-wrap">
                            <div className="footer-light">
                                <div className="line-item">
                                    <span className="small">v {versionNumber(RELEASE)} </span>
                                    {gettext('&copy; 2025 BCD Travel, All rights reserved. AgentSource&reg; and BCD Travel&reg; are registered trademarks of BCD Group.').replace(/&copy;/g, '©').replace(/&reg;/g, '®')}
                                </div>

                                <div className="sub-nav">
                                    <a href="https://www.bcdtravel.com/privacy-policy/" target="_blank">
                                        { gettext('Privacy Policy') }
                                    </a>
                                    <Link to="/ui/terms-conditions/">{gettext('Terms and Conditions')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
