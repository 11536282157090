import flow from 'lodash/flow';
import flatMap from 'lodash/flatMap';
import debounce from 'debounce-promise';
import settings from 'airborne/settings';
import api from 'midoffice/helpers/api';

import normalize from 'airborne/air/shared/helpers/normalize';
import {parseRecentAir, nopAborted} from './helpers/autocomplete';

const debouncedApi = debounce(api, 200);

/* eslint-disable immutable/no-mutation */
export default class AirDestinationAutocomplete {
    id = 'air_destination_autocomplete_default';
    api = api;
    system = settings.SUB_PARTNER || 'aft';
    locale = settings.USER_LOCALE || 'en_US';

    recentEndpoint = '/internal/get_air_recent_searches/';
    lookupEndpoint = '/latest/air/destination_lookup';

    icons = {
        c: 'map-pin',
        a: 'airplane',
    }

    constructor(id, applyDebounce = true) {
        if (id) {
            this.id = id;
            this.api = applyDebounce ? debouncedApi : api;
        }
    }

    getRecentSearches() {
        return api('GET', this.recentEndpoint, {id: this.id})
            .then(parseRecentAir, nopAborted);
    }

    parseItems(items) {
        return items.map(item => {
            const {
                id,
                iataCode,
                label,
                latitude,
                longitude,
                type,
                nested,
                head,
            } = item;

            return {
                value: id,
                icon: this.icons[type],
                lat: latitude,
                lon: longitude,
                iataCode,
                label,
                type,
                nested,
                head,
            };
        });
    }

    parseAirport(airport, nested) {
        return {
            ...airport,
            type: 'a',
            head: false,
            nested,
        };
    }

    parseCity(city) {
        return {
            ...city,
            type: 'c',
            head: true,
            nested: false,
        };
    }

    sortByScore(items) {
        return [...items].sort((a, b) => {
            return b.score - a.score;
        });
    }

    unfoldAirports(items) {
        const unfolded = items.map(item => {
            const {airports, ...city} = item;

            if (airports) {
                return [
                    city,
                    ...airports.map(airport => this.parseAirport(airport, true)),
                ];
            }

            return city;
        });

        return flatMap(unfolded);
    }

    parseLookup(response) {
        const data = normalize(response);
        const {cities, airports} = data;
        const items = flow(
            this.sortByScore.bind(this),
            this.unfoldAirports.bind(this),
            this.parseItems.bind(this),
        )([
            ...cities.map(city => this.parseCity(city)),
            ...airports.map(airport => this.parseAirport(airport, false)),
        ]);

        if (!items.length) {
            return null;
        }

        return items;
    };

    async query(term) {
        term = term.trim();
        if (term.length < 2) {
            api.abortMatching(this.id);
            return Promise.reject(null);
        }

        const data = {
            term,
            locale: this.locale,
            system: this.system,
        };

        return this.api(
            'GET',
            this.lookupEndpoint,
            {id: this.id, data}
        ).then(this.parseLookup.bind(this), nopAborted);
    }

    empty({recentSearches = true}) {
        if (!recentSearches) {
            return Promise.resolve(null);
        }
        return this.getRecentSearches();
    }
}
