import auditlogs from 'midoffice/modules/auditlogs';
import users from 'midoffice/modules/users';
import apiKeys from 'midoffice/modules/apikeys';
import bookings from 'midoffice/modules/bookings';
import companies from 'midoffice/modules/companies';
import agencies from 'midoffice/modules/agencies';
import pnrTool from 'midoffice/modules/pnrTool';
import labeling from 'midoffice/modules/labeling';
import providerCredentials from 'midoffice/modules/providerCredentials';
import aftMessaging from 'midoffice/modules/aftMessaging';
import flags from 'midoffice/modules/flags';
import usersettings from 'airborne/user_settings2/module';

function tabsAdmin() {
    return [
        ...auditlogs,
        apiKeys,
        users,
        bookings,
        companies,
        agencies,
        pnrTool,
        flags,
        usersettings,
        labeling,
        providerCredentials,
        aftMessaging,
    ];
}

function tabsAFT() {
    return [
        bookings,
    ];
}

export function modules(sub) {
    if (sub === 'aft') { return tabsAFT(); }
    else if (sub === 'admin') { return tabsAdmin(); }
}
