import partial from 'lodash/partial';
import 'midoffice/auditlogs/usersAuditlogs/types';
import {
    clearTags,
    removeTag,
    changeForm,
    serverPaginateCollection,
    serverSearchCollection,
    serverSortCollection,
} from 'midoffice/data/actions/serverFiltered';
import {paginateCollection} from 'midoffice/data/actions/common';

const endpoint = 'usersAuditlogs';
const filtersEndpoint = 'usersAuditlogFilters';

export const setPaginateLogs = partial(paginateCollection, endpoint);
export const sortLogs = partial(serverSortCollection, endpoint, filtersEndpoint);
export const paginateLogs = partial(serverPaginateCollection, endpoint, filtersEndpoint);
export const searchLogs = partial(serverSearchCollection, endpoint, filtersEndpoint);
export const changeFiltersForm = partial(changeForm, filtersEndpoint);
export const clearFilterTags = partial(clearTags, searchLogs, filtersEndpoint);
export const removeFilterTag = partial(removeTag, searchLogs, filtersEndpoint);
