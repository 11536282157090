import applyFilters from 'midoffice/helpers/filters';
import {
    includesPreference,
    PREFERRED_AIRLINES_CHOICES,
} from 'airborne/air/fare_search/helpers';
import gettext from 'airborne/gettext';
import range from 'lodash/range';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import snakeCase from "lodash/snakeCase";

const getSegmentsLengths = values => Array.isArray(values)
    // 1 segment - Direct
    // 2 segments - 1 Stop
    // 3+ segments - 2+ Stops. Adding all possible length value between
    // 3 and max to make `in` filter work with all options with 3+ segments.
    // [1]->[1]; [1,2]->[1,2]; [4]->[3,4], [1,5]->[1,3,4,5]
    ? values.reduce((acc, segmentsNumber) => [
        ...acc,
        ...((segmentsNumber >= 3)
            ? range(3, segmentsNumber + 1)
            : [segmentsNumber]),
    ], [])
    : null;

export const getFlightOptionFilters = (filtersValue, currentOD) => {
    const {stops, airlines, alliances, onlyAvailable, time, flightNumber, preference} = filtersValue;
    const {departure = {}, arrival = {}} = time?.[currentOD] || {};
    const {COMPANY_PREFERRED, BCD_PREFERRED} = PREFERRED_AIRLINES_CHOICES;

    const filters = [
        {field: 'segments.length', op: 'in', value: getSegmentsLengths(stops)},
        {field: 'airlines', op: 'intersect', value: airlines || null},
        {field: 'alliances', op: 'intersect', value: alliances || null},
        {field: 'allSegmentsAvailable', op: 'eq', value: onlyAvailable?.[0] || null},
        {field: 'departureMinutes', op: 'gte', value: departure.min},
        {field: 'departureMinutes', op: 'lte', value: departure.max},
        {field: 'arrivalMinutes', op: 'gte', value: arrival.min},
        {field: 'arrivalMinutes', op: 'lte', value: arrival.max},
        {field: COMPANY_PREFERRED, op: 'eq', value: includesPreference(preference, COMPANY_PREFERRED) || null},
        {field: BCD_PREFERRED, op: 'eq', value: includesPreference(preference, BCD_PREFERRED) || null},
    ];
    if (flightNumber && flightNumber[currentOD]) {
        return [...filters, {field: 'flightNumbers', op: 'intersect', value: [flightNumber[currentOD].number]}];
    }
    return filters;
};

const flightOptionExclusionCheck = flightOption => {
    return flightOption.currentlyBooked;
};

export const extractFilterableValues = (flightOptions, filterableField) => uniq(
    flatten(
        flightOptions.map((flightOption) => flightOption[filterableField] ?? [])
    )
).sort();

export const extractAirlines = (flightOptions) => extractFilterableValues(flightOptions, 'airlines');
export const extractAlliances = (flightOptions) => extractFilterableValues(flightOptions, 'alliances');

export const applyAirAvailabilityFilters = (flightOptions, filtersValue, currentOD) => {
    return applyFilters(flightOptions, getFlightOptionFilters(filtersValue, currentOD), flightOptionExclusionCheck);
};

export const createAvailabilityStopsChoices = flightOptions => {
    const stops = flightOptions.reduce(
        ({direct, one, two, max}, {segments: {length}}) => ({
            direct: length === 1 ? direct + 1 : direct,
            one: length === 2 ? one + 1 : one,
            two: length >= 3 ? two + 1 : two,
            max: Math.max(length, max),
        }),
        {direct: 0, one: 0, two: 0, max: 0}
    );

    return [
        [1, gettext('Direct'), stops.direct],
        [2, gettext('1 Stop'), stops.one],
        [stops.two ? stops.max : 0, gettext('2+ Stops'), stops.two],
    ];
};

export const TEST_ID_PREFIX = 'air-availability-filters';

export const createTestIdsBy = (choices, filterType, getUniqueStringFromChoice) =>
    choices.map((choice) => `${TEST_ID_PREFIX}-${filterType}-${snakeCase(getUniqueStringFromChoice(choice))}`);


export const createTestIdsByLabels = (choices, filterType) => createTestIdsBy(choices, filterType, ([, label]) => label);

export const createTestIdsByParts = (choices, filterType) => createTestIdsBy(choices, filterType, ([part]) => part);
