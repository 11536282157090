import flow from 'lodash/flow';
import get from 'lodash/get';
import concat from 'lodash/fp/concat';
import uniq from 'lodash/fp/uniq';
import map from 'lodash/fp/map';
import compact from 'lodash/fp/compact';
import {isErrorCodeRecoverable} from 'airborne/search2/helpers/apiError';


const PROVIDER_PROCESSING_ERROR_CODE = 5006000;

function isProviderProcessingError(error) {
    return error.error_code === PROVIDER_PROCESSING_ERROR_CODE;
}

export function getErrorMessage(error) {
    return isProviderProcessingError(error) ? error.developer_message : error.message;
}

export const getErrors = response =>
    get(response, 'body.errors', []);

export const getErrorCode = error => error.error_code;

export const getErrorField = error => error.field;

export const getFieldWithMessage = error => [getErrorField(error), getErrorMessage(error)];

export const isValidationError = error => error.reason === 'validation';

export const getDeveloperErrorMessages = response => {
    const errors  = flow(
        getErrors,
        map(error => error['developer_message']),
    )(response);

    return errors.length ? errors : null;
};

export const getErrorMessages = response => {
    const errorMessages = flow(
        getErrors,
        map(getErrorMessage),
    )(response);

    return errorMessages.length ? errorMessages : null;
};

export const getErrorCodes = response => {
    const errorCodes = flow(
        getErrors,
        map(getErrorCode)
    )(response);

    return errorCodes.length ? errorCodes : null;
};

export const getAllErrorMessages = response => {
    const developerErrorMessages = getDeveloperErrorMessages(response);
    const errorMessages = getErrorMessages(response);

    const messages = flow(
        concat,
        compact,
        uniq,
    )(errorMessages, developerErrorMessages);

    return messages.length ? messages : null;
};

export const getValidationErrors = response =>
    getErrors(response).filter(error =>
        isErrorCodeRecoverable(getErrorCode(error))
        && isValidationError(error)
    );

