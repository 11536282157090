import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import {dropEmptyDeep} from 'airborne/air/shared/helpers/emptyDeep';
import flatMap from 'lodash/flatMap';
import {generateId, resetId} from 'airborne/air/shared/helpers/id';
import normalize from 'airborne/air/shared/helpers/normalize';

const seatIdScope = Symbol('Seat ID scope');

const UNBOOKABLE_SEAT_CODE = 'unbookable_paid_seat';

export const DECK_CODES = {
    // M - means main deck
    M: 'M'
};

export const SEAT_TYPES = {
    index: 'INDEX',
    class: 'CLASS',
    side: 'SIDE',
    seat: 'SEAT',
    column: 'COLUMN',
    aisle: 'AISLE',
    exit: 'EXIT',
    empty: 'EMPTY',
    firstOverwing: 'FIRST_OVERWING',
    overwing: 'OVERWING',
};

export const POSITIONS = {
    // W - means window seat
    W: 'W',
    // A - means aisle seat
    A: 'A',
    // C - means center seat
    C: 'C',
    // WA/AW - means window on one side and aisle on the other side
    WA: 'WA',
    AW: 'AW',
};

export const SEAT_STATUSES = {
    // T - means taken
    T: 'T',
    // A - means available
    A: 'A',
    // N - means no seat at this location,
    N: 'N',
};

export const ROW_CHARACTERISTICS = {
    // Z - means row doesn't exist
    Z: 'Z',
    // E - means exit row
    E: 'E',
    // K - means overwing row
    K: 'K',
};

export const SEAT_CHARACTERISTICS = {
    // OW - means overwing seat(s)
    OW: 'OW',
    // 8 - means seat doesn't exist
    '8': '8',
    // L - means Leg space seat
    L: 'L',
};

export function getDescriptions(characteristics) {
    return characteristics?.map(char => char.description).filter(Boolean);
}

export function getSeatIdentity(row, seat) {
    return `${row?.number}${seat?.column}`;
}

export function isAvailableSeat(seat) {
    return seat?.status?.code === SEAT_STATUSES.A;
}

function getUnbookableSeatRestriction(seat) {
    const {info} = seat;
    const {restrictions = []} = info;
    return restrictions.find(({code}) => code === UNBOOKABLE_SEAT_CODE);
}

export function isUnbookableSeat(seat) {
    return Boolean(getUnbookableSeatRestriction(seat));
}

export function isLegSpaceSeat(seat) {
    return Boolean(
        seat?.characteristics?.find(char => char.code === SEAT_CHARACTERISTICS.L)
    );
}

function isWindowPosition(position) {
    return [
        POSITIONS.W,
        POSITIONS.WA,
        POSITIONS.AW
    ].includes(position?.code);
}

function isAislePosition(position) {
    return [
        POSITIONS.A,
        POSITIONS.WA,
        POSITIONS.AW,
    ].includes(position?.code);
}

function isEmptySeat(seat) {
    return Boolean(
        !seat ||
        seat.characteristics?.find(char => char.code === SEAT_CHARACTERISTICS['8']) ||
        seat.status?.code === SEAT_STATUSES.N
    );
}

function isOverwingSeat(seat) {
    return Boolean(
        seat?.characteristics?.find(char => char.code === SEAT_CHARACTERISTICS.OW)
    );
}

function isExitRow(row) {
    const {characteristics} = row;

    return Boolean(characteristics?.find(char => char?.code === ROW_CHARACTERISTICS.E));
}

function isRowExist(row) {
    const {characteristics} = row;

    return !characteristics?.find(char => char?.code === ROW_CHARACTERISTICS.Z);
}

function isOverwingRow(row) {
    const {characteristics, seats} = row;

    return Boolean(
        characteristics?.find(char => char?.code === ROW_CHARACTERISTICS.K)
        || seats.some(isOverwingSeat)
    );
}

function getExistRowByIndex(rows, rowIndex) {
    const row = rows[rowIndex];

    if (!row) {
        return null;
    }

    return isRowExist(row) ? row : getExistRowByIndex(rows, rowIndex - 1);
}

function isFirstOverwingRow(row, rowIndex, deck) {
    const {number} = row;
    const {overwingRowNumbers, rows} = deck;
    const prevRow = getExistRowByIndex(rows, rowIndex - 1);
    const first = Number(overwingRowNumbers?.first);

    return (
        isOverwingRow(row)
        && (
            number === first
            || !prevRow
            || !isOverwingRow(prevRow)
        )
    );
};


function createSeat({type, content, info, row, deck}) {
    const miniDeck = pick(deck, 'cabin', 'location');
    const miniRow = pick(row, 'number');
    const key  = String(generateId(seatIdScope));

    return dropEmptyDeep({
        type,
        content,
        info,
        key,
        deck: miniDeck,
        row: miniRow,
    });
}

function getHeadRowSeat(column, index, array) {
    const {position, index: colIndex} = column;
    const {position: nextPosition} = get(array, [index + 1], {});
    const {length} = array;
    let matrixSeats = [createSeat({type: SEAT_TYPES.column, content: colIndex})];

    if (isWindowPosition(position)) {
        matrixSeats.unshift(createSeat({type: SEAT_TYPES.side}));
        if (index >= length / 2) matrixSeats.reverse();
    }

    if (isAislePosition(position) && isAislePosition(nextPosition)) {
        matrixSeats.push(createSeat({type: SEAT_TYPES.aisle}));
    }

    return matrixSeats;
}

function getIndexSeat(row, rowIndex, deck) {
    const {number} = row;
    const params = {content: String(number)};

    if (isFirstOverwingRow(row, rowIndex, deck)) {
        return createSeat({type: SEAT_TYPES.firstOverwing, ...params});
    }
    if (isOverwingRow(row)) {
        return createSeat({type: SEAT_TYPES.overwing, ...params});
    }
    return createSeat({type: SEAT_TYPES.index, ...params});
}

function getSideSeat(row) {

    if (isExitRow(row)) {
        return createSeat({type: SEAT_TYPES.exit});
    }

    return createSeat({type: SEAT_TYPES.side});
}

function compileRow(matrixHead, row, rowIndex, deck) {
    const {seats} = row;
    const processedSeats = flatMap(matrixHead, ((column, index, array) => {
        const length = array.length;
        const seat = seats.find(seat => seat.column === column.index);
        const {position} = column;
        const {position: nextPosition} = get(matrixHead, [index + 1], {});

        const type = isEmptySeat(seat) ? SEAT_TYPES.empty : SEAT_TYPES.seat;
        const params = {info: seat, deck, row};

        let matrixSeats = [createSeat({type, ...params})];

        if (isWindowPosition(position)) {
            matrixSeats.unshift(getSideSeat(row));
            if (index >= length / 2) matrixSeats.reverse();
        }

        if (isAislePosition(position) && isAislePosition(nextPosition)) {
            matrixSeats.push(createSeat({type: SEAT_TYPES.aisle}));
        }

        return matrixSeats;

    }));

    return [
        getIndexSeat(row, rowIndex, deck),
        ...processedSeats,
        getIndexSeat(row, rowIndex, deck),
    ];
}


export function getCabinName(cabin) {

    if (!cabin) {
        return '';
    }

    const {code, description} = cabin;
    const capDescription = capitalize(description).replace(/_/g, ' ');

    return capDescription ? `${capDescription} (${code})` : code;
}

function parseDeck(deck) {
    const {columns, rows, cabin} = deck;
    const matrixHead = sortBy(columns, column => column.index);

    const headRow = [
        createSeat({type: SEAT_TYPES.class, content: getCabinName(cabin)}),
        ...flatMap(matrixHead, ((seat, index, array) => getHeadRowSeat(seat, index, array))),
        createSeat({type: SEAT_TYPES.index})
    ];
    const processedRows = rows
        .filter(isRowExist)
        .map((row, rowIndex) => compileRow(matrixHead, row, rowIndex, deck));

    const seatsMatrix = [headRow, ...processedRows];

    return {
        ...pick(deck, ['cabin', 'location']),
        seatsMatrix,
    };
}

export function parseSeatMap(response) {
    resetId(seatIdScope);
    const {decks, ...rest} = normalize(response);

    return {
        ...rest,
        decks: decks?.map(parseDeck)
    };
}

