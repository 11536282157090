import moment from 'moment';
import some from 'lodash/some';
import map from 'lodash/map';

import gettext from 'airborne/gettext';

import {createSchema, required, combineValidators, noErrors} from 'midoffice/newforms/helpers';
import {
    NumberField,
    Field,
    CharField,
    BooleanField,
    ArrayField,
    DateTimeRange,
    UpperCaseField,
} from 'midoffice/newforms/fields-stateless';
import {Schema} from 'midoffice/newforms/schema-stateless';
import {AlphanumericField} from 'midoffice/newforms/fields-stateless';

import {isAcriss, isPseudo} from 'airborne/cars/homepage/helpers/acriss';
import {getDateLimits} from 'airborne/cars/helpers/date';


const wrapErrorForTagField = (fn)=> (...args)=> {
    const errors = fn(...args);
    return errors ? [errors] : null;
};

const validateUniqueness = (error)=> (list)=> {
    if (!list || !list.length) { return null; }

    const errors = list.map((item, index, list)=> {
        const firstIndex = list.indexOf(item);
        const lastIndex = list.lastIndexOf(item);

        if ((firstIndex !== lastIndex) && (firstIndex !== index)) {
            return error;
        }

        return null;
    });

    return some(errors) ? errors : null;
};


const PickUpDropOffRange = {
    ...DateTimeRange,

    validate(value={}, {strict=false}={}) {
        const {min: pickup, max: dropoff} = value;

        const pickupDateTime = moment(pickup, 'YYYY-MM-DDTHH:mm');
        const dropoffDateTime = moment(dropoff, 'YYYY-MM-DDTHH:mm');
        const {min: today, max: tooFar} = getDateLimits();

        if (pickup && pickupDateTime.diff(today, 'days') < 0) {
            return gettext('Pick-up date can\'t be in the past');
        }
        if (pickup && pickupDateTime.diff(tooFar, 'days') >= 0) {
            return gettext('Pick-up date can\'t be that far in the future');
        }

        if (pickup && dropoff && pickupDateTime >= dropoffDateTime) {
            return gettext('Drop-off can\'t be earlier than pick-up');
        }

        if (!strict && pickup !== null && dropoff !== null) {
            return null;
        }

        if (!pickupDateTime.isValid() || !dropoffDateTime.isValid()) {
            return {
                min: pickupDateTime.isValid() ? null : gettext('Please enter your pick-up date'),
                max: dropoffDateTime.isValid() ? null : gettext('Please enter your drop-off date'),
            };
        }
    }
};

export const DiscountNumber = createSchema({
    fields: {
        number: {
            ...AlphanumericField,
            maxLength: 25,
            maxLengthMessage: (field)=> gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: ()=> gettext('Ensure this field has only alphanumeric characters'),
        },
        vendor: CharField,
    },
});

const NegotiatedNumber =  createSchema({
    fields: {
        customInput: {
            ...AlphanumericField,
            maxLength: 25,
            maxLengthMessage: (field)=> gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: ()=> gettext('Ensure this field has only alphanumeric characters'),
        },
        number: {
            ...AlphanumericField,
            maxLength: 25,
            maxLengthMessage: (field)=> gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: ()=> gettext('Ensure this field has only alphanumeric characters'),
        },
        agencyNumber: {
            ...AlphanumericField,
            maxLength: 25,
            maxLengthMessage: (field)=> gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: ()=> gettext('Ensure this field has only alphanumeric characters'),
        },
        vendor: CharField,
    },
});

export const IdNumbersField = {
    ...ArrayField,

    child: DiscountNumber,

    validate: combineValidators(
        ArrayField.validate,
        (value)=> {
            const vendors = map(value, 'vendor');
            return validateUniqueness(gettext('Vendor is not unique'))(vendors);
        }
    ),

    toRepresentation(formValue, options) {
        let {value, errors} = ArrayField.toRepresentation.call(this, formValue, options);

        if (!value || !value.length) { value = []; }
        return {value, errors};
    }
};

const NegotiatedNumbersField = {
    ...ArrayField,
    child: NegotiatedNumber,
    validate: combineValidators(
        ArrayField.validate,
        (value)=> {
            const vendors = map(value, 'vendor');
            return validateUniqueness(gettext('Vendor is not unique'))(vendors);
        }
    ),

    toRepresentation(formValue, options) {
        let {value, errors} = ArrayField.toRepresentation.call(this, formValue, options);

        if (!value || !value.length) { value = [{}]; }
        return {value, errors};
    }};

const NegotiatedIdNumber =  createSchema({
    fields: {
        customIdInput: {
            ...AlphanumericField,
            maxLength: 25,
            maxLengthMessage: (field)=> gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: ()=> gettext('Ensure this field has only alphanumeric characters'),
        },
        idNumber: {
            ...AlphanumericField,
            maxLength: 25,
            maxLengthMessage: (field)=> gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: ()=> gettext('Ensure this field has only alphanumeric characters'),
        },
        vendor: CharField,
    },
});

const NegotiatedIdNumbersField = {
    ...ArrayField,
    child: NegotiatedIdNumber,
    validate: combineValidators(
        ArrayField.validate,
        (value)=> {
            const vendors = map(value, 'vendor');
            return validateUniqueness(gettext('Vendor is not unique'))(vendors);
        }
    ),

    toRepresentation(formValue, options) {
        let {value, errors} = ArrayField.toRepresentation.call(this, formValue, options);

        if (!value || !value.length) { value = [{}]; }
        return {value, errors};
    }};


const MAX_LENGTH = 5;
function validateQuantity(carTypes) {
    if (!carTypes || carTypes.length <= MAX_LENGTH) { return null; }
    return carTypes.map((code, index)=> (
        index < MAX_LENGTH
            ? null
            : gettext('Enter up to {maxLength} car types', {maxLength: MAX_LENGTH})
    ));
}

export function validateCarTypeFormat(carTypes, {gds}={}) {
    if (!carTypes || !carTypes.length) { return null; }

    const allowWildcard = gds !== 'sabre';
    const errors = carTypes.map((type)=> (
        isAcriss(type, allowWildcard) || isPseudo(type) ? null : gettext('Invalid ACRISS code')
    ));

    return some(errors) ? errors : null;
}

function validateSabrePseudoCode(carTypes, {gds}={}) {
    if (!carTypes || !carTypes.length || gds !== 'sabre') { return null; }

    const onePseudoError = gettext('Only one pseudo code is allowed');
    const acrissPseudoError = gettext('Mixing pseudo code with ACRISS codes is not allowed');

    const [errors] = carTypes.reduce(([acc, pseudoPresent], current)=>{
        if (pseudoPresent) {
            return [
                [...acc, isPseudo(current) ? onePseudoError : acrissPseudoError],
                true,
            ];
        }
        if (isPseudo(current)) {
            return [
                [...acc, acc.length ? acrissPseudoError : null],
                true,
            ];
        }
        return [
            [...acc, null],
            false,
        ];
    }, [[], false]);
    return some(errors) ? errors : null;

}

export const CarTypes = {
    ...ArrayField,

    child: UpperCaseField,

    validate: combineValidators(
        wrapErrorForTagField(validateQuantity),
        wrapErrorForTagField(validateCarTypeFormat),
        wrapErrorForTagField(validateSabrePseudoCode),
        wrapErrorForTagField(validateUniqueness(gettext('Value is not unique'))),
    ),
};


const getCarsSearchSchema = (enableCompanyIdField) => createSchema({
    fields: {
        pickUp: required(Field, ()=> gettext('Where do you want to pick up your car?')),
        dropOff: Field,
        pickUpDistance: NumberField,
        dropOffDistance: NumberField,
        differentDropOff: BooleanField,
        acriss: required(CarTypes),
        dates: createSchema(PickUpDropOffRange),

        idNumbers: enableCompanyIdField ? NegotiatedIdNumbersField : IdNumbersField,
        cdNumbers: NegotiatedNumbersField,
        itNumbers: NegotiatedNumbersField,
    },

    validate(value, options) {
        let errors = Schema.validate.call(this, value, options) || {};

        const {differentDropOff, dropOff} = value;

        if (!value.pickUp || !value.pickUp.label) {
            errors = {...errors, pickUp: gettext('Where do you want to pick up your car?')};
        }
        if (differentDropOff && !dropOff) {
            errors = {...errors, dropOff: gettext('Where do you want to drop off your car?')};
        }

        return noErrors(errors) ? null : errors;
    },
});

export default getCarsSearchSchema;
