import every from 'lodash/every';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import moment from 'moment';
import gettext from 'airborne/gettext';
import applyFilters from 'midoffice/helpers/filters';
import {
    getODFilters,
    getGroupFilters,
    getLongFlightDurationByRange,
    getFlightOptionsByODIndex,
    getDurationRangeFromFOs, diffInDays, getFullFlightCodeFromSegment,
} from 'airborne/air/fare_search/helpers/index';
import {airProviderCode} from 'midoffice/helpers/format';
import settings from 'airborne/settings';
import {getFareType} from './fareType';
import {applyStopsFilter} from './stopsFilter';
import {getFareAirlines, getGroupFlightNumbers, getMaxDuration} from "airborne/air/fare_search/types";

export const getDescription = (fareComponents) => {
    return uniqBy(fareComponents, 'fareBasisCode')
        .map((component) => {
            const {
                fareBasisCode,
                priceClassName,
                priceClassDescription,
                isNegotiated,
                isBcdNegotiated,
                isCompanyNegotiated,
            } = component;
            const fareType = getFareType({
                isNegotiated,
                isBcdNegotiated,
                isCompanyNegotiated,
            });

            return [fareType, priceClassName, priceClassDescription, fareBasisCode || gettext('Unknown')];
        })
        .map((fareTuple, index, fareTuples) => {
            const [currentFareType] = fareTuple;
            const prevFareType = fareTuples[index - 1]?.[0];
            const fareType = currentFareType === prevFareType ? null : currentFareType;

            return [fareType, ...fareTuple.slice(1)];
        });
};

export const getCarrierCode = (segment) => {
    const {carrier, operatingCarrier} = segment;

    return operatingCarrier?.code || carrier.code;
};

export const getLongDurationsByODIndex = (fareGroups) => {
    const flightOptionsByODIndex = getFlightOptionsByODIndex(fareGroups);

    return flightOptionsByODIndex.map((flightOptions) => {
        const range = getDurationRangeFromFOs(flightOptions);

        return getLongFlightDurationByRange(range);
    });
};


const filterOptionsByLongFlight = (longDurationsByDest, OD, ODIndex) => {
    return OD.flightOptions.filter((option) => {
        return option.durationMinutes < longDurationsByDest[ODIndex];
    });
};

const filterByLongFlight = (fareGroups, exclusions) => {
    const longDurationsByDest = getLongDurationsByODIndex(fareGroups);
    return fareGroups.map((group) => {
        const originDestinations = group.originDestinations.map((OD, ODIndex) => ({
            ...OD,
            flightOptions: (exclusions[group.fareGroupKey] === ODIndex) ? OD.flightOptions
                : filterOptionsByLongFlight(longDurationsByDest, OD, ODIndex),
        }));

        return {
            ...group,
            flightNumbers: getGroupFlightNumbers(originDestinations),
            maxDurationMinutes: getMaxDuration(originDestinations),
            airlines: getFareAirlines(originDestinations),
            originDestinations
        }
    });
};

export const applyAirFilters = (fareGroups, filters, exclusions) => {
    if (!fareGroups || !fareGroups.length) {
        return [];
    }

    // Filters might differ depending on ODIndex i.e depart/return
    const filtersByOD = fareGroups[0].originDestinations.map((_od, ODIndex) =>
        getODFilters(filters, ODIndex));

    const fareData = filters.excludeLongFlightByDest ? filterByLongFlight(fareGroups, exclusions) : fareGroups;

    const isExclusionFlightOption = (group, OD, ODIndex) =>
        exclusions[group.fareGroupKey] === ODIndex || OD.flightOptions.some(FO => FO.currentlyBooked);

    return applyFilters(fareData, getGroupFilters(filters))
        .filter((fareGroup) => applyStopsFilter(fareGroup, filters))
        .map(group => ({
            ...group,
            originDestinations: [
                ...group.originDestinations.map((OD, ODIndex) => ({
                    ...OD,
                    flightOptions: isExclusionFlightOption(group, OD, ODIndex)
                        ? OD.flightOptions
                        : applyFilters(
                            OD.flightOptions,
                            filtersByOD[ODIndex],
                        )
                })),
            ],
        })).filter(({originDestinations}) => every(originDestinations, 'flightOptions.length'));
};

export const isNdcFare = (fareGroup) => {
    const {isNdc} = fareGroup;

    return isNdc;
};

export const isLccFare = (fareGroup) => {
    const {provider, isNdc} = fareGroup;

    return provider === 'travelfusion' && !isNdc;
};

export const getProviderType = fareGroup => {
    const {provider} = fareGroup;

    if (!provider) {
        return null;
    }

    if (settings.GDS_LIKE_PROVIDERS.includes(provider)) {
        return 'GDS';
    }

    if (provider === 'farelogix' || provider === 'ba') {
        return `NDC-${airProviderCode(provider)}`;
    }

    if (isLccFare(fareGroup)) {
        return 'LCC';
    }

    if (isNdcFare(fareGroup)) {
        return 'NDC';
    }

    return airProviderCode(provider);
};

export const getProviderCode = fareGroup => {
    const {provider} = fareGroup;
    const providerType = getProviderType(fareGroup);

    if (providerType === 'GDS') {
        return airProviderCode(provider);
    }

    return providerType;
};

export const extractQuickOfferFromSegments = (segments = []) => {
    const dateFormat = settings.USER.date_format_str;

    const [segment] = segments;
    const lastSegment = segments[segments.length - 1];

    const carrierName = get(segment, 'carrier.name', '');
    const departureAiprortCode = get(segment, 'departure.airport.code', '');
    const arrivalAiprortCode = get(lastSegment, 'arrival.airport.code', '');

    const departureDateTime = get(segment, 'departure.datetime', '');
    const arrivalDateTime = get(lastSegment, 'arrival.datetime', '');

    const date = moment(departureDateTime).format(dateFormat);
    const departureTime = moment(departureDateTime).format('hh:mm A');
    const arrivalTime = moment(arrivalDateTime).format('hh:mm A');

    const diffDays = diffInDays(moment(arrivalDateTime), moment(departureDateTime));

    const diffDaysFormatted = diffDays > 0 ? '+' + diffDays + 'd' : '';

    const fullFlightCode = getFullFlightCodeFromSegment(segment);
    const flightCodeFormatted = fullFlightCode ? `(${fullFlightCode})` : '';

    return `${carrierName} ${flightCodeFormatted} - ${departureAiprortCode}-${arrivalAiprortCode}` + '\n' +
            date + '\n' +
           `${departureTime} - ${arrivalTime}${diffDaysFormatted}` + '\n' + '\n';
}

export const formatFareTotal = (fare) => `${gettext('Total:')} ${fare.total} ${fare.currency}`;

const extractQuickOffer = (fare, getSegments) => {
    const quickOffer = fare.originDestinations.reduce((acc, originDestination, index) => {
        return acc + extractQuickOfferFromSegments(getSegments(originDestination, index))
    }, '');

    return quickOffer + formatFareTotal(fare);
};

export const formatFareGroupQuickOffer = (fareGroup, flightOptionKeys = []) => {
    return extractQuickOffer(fareGroup, (originDestination, index) => {
        const flightOptionKey = flightOptionKeys[index];
        const [firstFlightOption] =  originDestination.flightOptions;

        const flightOption = flightOptionKey
            ? originDestination.flightOptions
                .find(option => option.flightOptionKey === flightOptionKey) || firstFlightOption
            : firstFlightOption

        return flightOption.segments;
    });
};


export const formatFareQuickOffer = (fare) => {
    return extractQuickOffer(fare, (originDestination) => {
        return originDestination.segments;
    });
};
