import moment from 'moment';

import {register} from 'airborne/types';
import {formatUrl} from 'midoffice/helpers/url';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

function isUsersAuditLogsEnabled() {
    return getFeatureFlag(null,'ENABLE_USER_AUDIT_LOGS', {canGetFromCookie: true});
}

function serialize({timestamp_min, timestamp_max, user, affected_user, ...params}) {  // eslint-disable-line camelcase
    return dropEmpty({
        ...params,
        user: (Array.isArray(user) && user.length)
            ? user.map(({value})=> value)
            : null,
        affected_user: (Array.isArray(affected_user) && affected_user.length)  // eslint-disable-line camelcase
            ? affected_user.map(({value})=> value)  // eslint-disable-line camelcase
            : null,
        timestamp_min: timestamp_min  // eslint-disable-line camelcase
            ? moment(timestamp_min).toISOString()  // eslint-disable-line camelcase
            : null,
        timestamp_max: timestamp_max  // eslint-disable-line camelcase
            ? moment(timestamp_max).toISOString()  // eslint-disable-line camelcase
            : null,
    });
}

function getAuditlogsPath() {
    return isUsersAuditLogsEnabled() ? '/api/audit-logs/entities/' : '/api/audit-logs/';
}

function fetchCollection(endpoint, params) {
    return formatUrl(getAuditlogsPath(), serialize(params));
}

register('auditlogs', {fetchCollection});
