import {createSelector} from 'reselect';

import {paginateRates, orderRates, filterRates} from 'airborne/cars/search/helpers/rates';


export function isRatesLoading(state) {
    return state.cars.rates.loading;
}

export const getRates = state => state.cars.rates.data;
export const getDeliveryCompanyLocations = state => state.cars.pickUpCompanyLocations.data;
export const getCollectionCompanyLocations = state => state.cars.dropOffCompanyLocations.data;

export const getCarsErrorCode = state => state.cars.rates.errorCode;

export function getRate(state, rateId) {
    return getRates(state)[rateId];
}

export const getOrder = state => state.cars.order;
export const getFilters = state => state.cars.filters;
export const getMapView = state => state.cars.mapView.showMap;

export const getCarsIdNumbers = state => state.cars.tspmIdNumbers;
export const getFilteredRates = createSelector([getRates, getFilters], filterRates);

export const getPagination = createSelector(
    [state => state.cars.pagination, getFilteredRates],
    (pagination, filteredRates)=> ({...pagination, totalPages: Math.ceil(filteredRates.length / pagination.pageSize)})
);

const getFilteredOrderedRates = createSelector([getFilteredRates, getOrder], orderRates);
const getFilteredOrderedPaginatedRates = createSelector([getFilteredOrderedRates, getPagination], paginateRates);

export const getRatesToShow = getFilteredOrderedPaginatedRates;
