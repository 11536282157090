import memoize from 'lodash/memoize';
import {createSchema, validateIf, required, combineValidators} from 'midoffice/newforms/helpers';
import {
    Field, ChoiceField, AlphanumericField, IdCardNumberField, CharField,
    ArrayField, BooleanField, StrictDateField, OptionalChoiceField, DateField,
} from 'midoffice/newforms/fields-stateless';
import {emptyMessage} from 'airborne/air/checkout/fields';

import range from 'lodash/range';
import moment from 'moment';
import gettext from 'airborne/gettext';
import {isPastDate} from 'airborne/air/checkout/helpers/checkoutForm';
import {DATE_FORMAT} from 'midoffice/data/types/aftMessaging';
import settings from 'airborne/settings';

export const manualPassportFormFields = [
    'passportNumber', 'passportCountryOfIssue', 'passportExpiryYear', 'passportDateOfIssue',
    'passportExpiryMonth', 'passportDateOfBirth', 'passportGender', 'passportNationality',
];

export const visaDraftFormFields = [
    'visaNumber', 'visaIssueMonth', 'visaIssueYear', 'visaExpiryMonth',
    'visaExpiryYear', 'visaCountry', 'visaIssuePlace', 'visaPlaceOfBirth'
];

export const idCardFormFields = [
    'idCardNumber', 'idCardExpiryMonth', 'idCardExpiryYear',
    'idCardCountry', 'idCardDateOfBirth', 'idCardGender'
];

export const VisaList = {
    ...ArrayField,
};

export const PassportList = {
    ...ArrayField,
};

export const IdCardList = {
    ...ArrayField,
};

export const TABS = {
    NOT_APPLICABLE: 'na',
    PASSPORT_AND_VISA: 'passports',
    ID_CARDS: 'id-cards',
};

const MIN_TRAVELER_AGE = 16;
const MIN_DOB_YEAR = 1900;

function getDOBField() {
    return {
        ...StrictDateField,
        maxDate: moment().subtract(MIN_TRAVELER_AGE, 'years'),
        minDate: moment([MIN_DOB_YEAR]),
        maxDateErrorMsg: gettext('Traveler must be at least {MIN_TRAVELER_AGE} years old', {MIN_TRAVELER_AGE}),
        minDateErrorMsg: gettext('Year of birth must be {MIN_DOB_YEAR} or greater', {MIN_DOB_YEAR})
    };
}

const PassportDateOfIssueField = {
    ...StrictDateField,
    format: settings.SHORT_DATE_FORMAT,
    // StrictDateField adds today as a prefilled date. Returning empty value prevents this behaviour
    toRepresentation(value) {
        if (!value) return '';

        return StrictDateField.toRepresentation(value);
    },

    toInternalValue(value) {
        if (!value) return '';

        return StrictDateField.toInternalValue(value);
    },

    validate: combineValidators(
        (value, {dateOfBirth} = {}) => {
            const dateOfIssue = moment(value, DATE_FORMAT);

            if (dateOfIssue.isBefore(moment(dateOfBirth, DATE_FORMAT))) {
                return gettext('Date of issue can not be earlier than date of birth');
            }

            if (dateOfIssue.isAfter(moment())) {
                return gettext('Future date is not allowed');
            }

            return null;
        },
        function (value) {
            return DateField.validate.call(this, value);
        },
    ),
};

function getGenderField() {
    return {
        ...OptionalChoiceField,
        choices: ['M', 'F', 'U', 'X'],
        missingChoiceMessage: gettext('Invalid choice value'),
    };
}

function getMonthRange(fromMonth = 1) {
    return range(fromMonth, 13).map((month)=> ([month, moment().month(month - 1).format('MMMM')]));
}

function getExpiryMonths(minDate, expiryYear) {
    const fromMonth = minDate && minDate.year() === expiryYear ? minDate.month() + 1 : 1;
    return getMonthRange(fromMonth);
}

function getYears(yearsPast, yearsFuture) {
    const yearNow = moment().year();
    const yearFrom = moment().subtract(yearsPast, 'years').year();
    return range(yearFrom, yearNow + yearsFuture).map((year) => ([year, year]));
}

const MonthField = {...ChoiceField, choices: getMonthRange()};
const VisaIssueYearField = {...ChoiceField, choices: getYears(11, 1)};

const IdCardExpiryMonthField = {
    ...OptionalChoiceField,

    validate: (value, {validateExpiration, requireExpiryMonth, idCardExpiryYear} = {}) => {
        const expired = Boolean(value) && Boolean(idCardExpiryYear) && isPastDate(idCardExpiryYear, value);

        if (requireExpiryMonth && !Boolean(value) && !expired) {
            return emptyMessage();
        }

        return validateExpiration && Boolean(value) && expired
            ? gettext('Id card has expired')
            : null;
    },
};

function getExpiryYearChoices(minDate) {
    const yearNow = moment().year();
    const minDateYear = minDate?.year();
    const yearPast = minDateYear ? yearNow - minDateYear : 0;
    return getYears(yearPast, 11);
}

function getIdCardExpiryYearField(minDate) {
    return {
        ...OptionalChoiceField,

        choices: getExpiryYearChoices(minDate),

        validate: (value, {validateExpiration, requireExpiryYear} = {}) => {
            const expired = Boolean(value) && value < moment().year();

            if (requireExpiryYear && !Boolean(value) && !expired) {
                return emptyMessage();
            }

            return validateExpiration && Boolean(value) && expired
                ? gettext('Id card has expired')
                : null;
        },
    }
}

function getExpiryYearField(minDate) {
    return {
        ...ChoiceField,
        choices: getExpiryYearChoices(minDate),
    };
}

const passportSchema = ({minDate, passportExpiryYear, visaExpiryYear, idCardExpiryYear} = {}) => createSchema({
    fields: {
        notApplicable: BooleanField,
        travelDocumentationTab: CharField,

        passportIndex: Field,
        visaIndex: Field,
        idCardIndex: Field,

        passportNumber: validateIf(
            required({
                ...AlphanumericField,
                minLength: 6,
                maxLength: 9
            }),
            'requirePassport'
        ),
        passportCountryOfIssue: validateIf(required(ChoiceField, emptyMessage), 'requirePassport'),
        passportExpiryMonth: validateIf(required({...ChoiceField, choices: getExpiryMonths(minDate, passportExpiryYear)}),  'requirePassport'),
        passportExpiryYear: validateIf(required(getExpiryYearField(minDate)), 'requirePassport'),
        passportDateOfBirth: validateIf(required(getDOBField()), 'requirePassport'),
        passportGender: validateIf(required(getGenderField()), 'requirePassport'),
        passportDateOfIssue: PassportDateOfIssueField,
        passportNationality: validateIf(required(ChoiceField, emptyMessage), 'requirePassport'),

        passports: PassportList,
        'visa_documents': VisaList,
        idCards: IdCardList,

        visaNumber: validateIf(required(AlphanumericField), 'requireVisaDraft'),
        visaIssueMonth: validateIf(required(MonthField), 'requireVisaDraft'),
        visaIssueYear: validateIf(required(VisaIssueYearField), 'requireVisaDraft'),
        visaExpiryMonth: validateIf(required({...ChoiceField, choices: getExpiryMonths(minDate, visaExpiryYear)}),  'requireVisaDraft'),
        visaExpiryYear: validateIf(required(getExpiryYearField(minDate)), 'requireVisaDraft'),
        visaCountry: validateIf(required(ChoiceField), 'requireVisaDraft'),
        visaIssuePlace: validateIf(required(CharField), 'requireVisaDraft'),
        visaPlaceOfBirth: validateIf(required(CharField), 'requireVisaDraft'),

        idCardNumber: validateIf(
            required({
                ...IdCardNumberField,
                minLength: 5,
                maxLength: 16
            }),
            'requireIdCard'
        ),
        idCardExpiryMonth: {...IdCardExpiryMonthField, choices: getExpiryMonths(minDate, idCardExpiryYear)},
        idCardExpiryYear: getIdCardExpiryYearField(minDate),
        idCardCountry: validateIf(required(ChoiceField), 'requireIdCard'),
        idCardDateOfBirth: validateIf(required(getDOBField()), 'requireIdCard'),
        idCardGender: validateIf(required(getGenderField()), 'requireIdCard'),

        usePassportAsFOID: BooleanField,
        useIdCardAsFOID: BooleanField,
    },
});

export default memoize(passportSchema);
