import {dropEmptyDeep} from 'airborne/helpers/emptyDeep';
import normalize from 'airborne/air/shared/helpers/normalize';
import {getTripType, isOneWayMode} from 'airborne/air/homepage/helpers/common';
import {AIR_TRIP_TYPES} from 'airborne/air/homepage/types';
import {
    INITIAL_DEPARTURE_CABIN,
    INITIAL_ARRIVAL_CABIN,
} from 'airborne/store/modules/homepage/reducers/destination';

const LOCATION_CODES = {
    'airport': 'a',
    'city': 'c',
};

function parseConnectingLocation(location) {
    if (!location) {
        return null;
    }

    const {
        locationCode,
        locationName,
        locationType,
    } = location;

    return {
        iataCode: locationCode,
        label: locationName,
        type: LOCATION_CODES[locationType],
    };
}

function extractConnectingLocations(destinations) {
    return destinations.map((destination) => {
        const {connectingLocations} = destination;

        return parseConnectingLocation(connectingLocations?.[0]);
    });
}

function parseTimeRestriction(timeRestriction) {
    if (!timeRestriction) {
        return {time: null, timeWindow: 2};
    }

    const {time, timeWindow} = timeRestriction;

    return {time, timeWindow};
}

function parseCommonOriginDestination(destination) {
    const {
        departureLocation,
        arrivalLocation,
        cabin,
        timeRestriction,
    } = destination;

    return {
        pickUp: {
            iataCode: departureLocation.locationCode,
            label: departureLocation.locationName,
            type: LOCATION_CODES[departureLocation.locationType]
        },
        dropOff: {
            iataCode: arrivalLocation.locationCode,
            label: arrivalLocation.locationName,
            type: LOCATION_CODES[arrivalLocation.locationType]
        },
        noCabinPreference: !cabin,
        departureCabin: cabin || INITIAL_DEPARTURE_CABIN,
        timeRestrictionOutbound: parseTimeRestriction(timeRestriction),
    };
}

function getRecentAirTripType(destinations) {
    const destinationsWithCommonParams = destinations.map(parseCommonOriginDestination);
    return getTripType(destinationsWithCommonParams);
}

function parseOneWayOriginDestinations(destinations) {

    return destinations.map((destination) => {
        const {departureDate} = destination;

        return {
            ...parseCommonOriginDestination(destination),
            dateRange: {
                min: departureDate,
                max: null,
            },
            timeRestrictionInbound: parseTimeRestriction(null),
            arrivalCabin: INITIAL_ARRIVAL_CABIN,
        };
    });
}

function parseRoundTripOriginDestinations(destinations) {
    const [outboundDestination, inboundDestination] = destinations;
    const {departureDate} = outboundDestination;
    const {departureDate: returnDate, timeRestriction, cabin} = inboundDestination;

    return [{
        ...parseCommonOriginDestination(outboundDestination),
        dateRange: {
            min: departureDate,
            max: returnDate,
        },
        timeRestrictionInbound: parseTimeRestriction(timeRestriction),
        arrivalCabin: cabin || INITIAL_ARRIVAL_CABIN,
    }];
}

function parseOriginDestinations(destinations, tripType) {
    switch (tripType) {
        case AIR_TRIP_TYPES.ROUND_TRIP:
            return parseRoundTripOriginDestinations(destinations);
        default:
            return parseOneWayOriginDestinations(destinations);
    }
}

function parseFrequentFlyerNumbers(item) {
    const {travelers: [traveler]} = item;
    const {ffNumbers} = traveler;

    return ffNumbers?.map(ffNumber => ({
        vendor: ffNumber.airlineCode,
        number: ffNumber.ffNumber,
    }));
}

function parseRecentItem(item) {
    const normalizedItem = normalize(item);
    const {
        originDestinations,
        expired,
        airlineCodes,
        airlineAlliances,
        includedBaggage: allowedBaggage,
        refundableOnly,
        excludePenalties,
        changeableOnly,
    } = normalizedItem;
    const [{
        directOnly: directFlights,
    }] = originDestinations;
    const tripType = getRecentAirTripType(originDestinations);
    const connections = extractConnectingLocations(originDestinations);
    const parsedDestinations = parseOriginDestinations(originDestinations, tripType);
    const oneWay = isOneWayMode(tripType);
    const ftNumbers = parseFrequentFlyerNumbers(normalizedItem);

    return dropEmptyDeep({
        isAir: true,
        recentItem: true,
        expired,
        airlineCodes,
        airlineAlliances,
        tripType,
        oneWay,
        directFlights,
        allowedBaggage,
        refundableOnly,
        excludePenalties,
        connections,
        originDestinations: parsedDestinations,
        changeableOnly,
        ftNumbers,
    });
}

export function parseRecentAirAutocomplete(items) {
    if (!items.length) {
        return null;
    }

    return items.map(parseRecentItem);
}
