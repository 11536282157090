/* jshint esnext:true */
'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import gettext from 'airborne/gettext';

import PageTitle from 'midoffice/components/PageTitle';
import {CommonTable} from 'midoffice/components/CommonTable';
import Pager from 'midoffice/components/Pager';
import format from 'midoffice/helpers/format';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import DiffModal from '../DiffModal';
import {setPaginateLogs, searchLogs, sortLogs, paginateLogs} from './actions';
import  AuditLogsTabs, {AUDIT_LOGS_TYPES} from '../AuditLogsTabs';
import Filters from './Filters';
import Tags from './Tags';


function valueFormat(value, id, isOldValue) {
    let oldNew = isOldValue ? 'old-value' : 'new-value';
    let link = value.is_json ? (<a href={`/audit-logs/users/json/${oldNew}/${id}`}>View JSON...</a>) : null;

    if (value.text) {
        return (<div><pre>{value.text}</pre>{link}</div>);
    }
    return link;
}

class LogEntry extends React.Component {
    static propTypes = {
        entry: PropTypes.any.isRequired,
        onCompare: PropTypes.func.isRequired,
    };

    handleClick = (event) => {
        const {entry, onCompare} = this.props;
        onCompare(event, entry);
    };

    render() {
        const {entry} = this.props;
        return (<tr key={entry.id}>
            <td>{entry.id}</td>
            <td>{format.date(entry.timestamp, 'iso')}</td>
            <td>{
                entry.is_info_truncated ?
                    gettext('There was an issue retrieving the user information due to the large size of the data.') :
                    entry.user
            }</td>
            <td>{entry.section}</td>
            <td>{valueFormat(entry.old_value, entry.id, true)}</td>
            <td>{valueFormat(entry.new_value, entry.id, false)}</td>
            <td>
                <DropdownButton title="More" align="end" id={entry.id} variant="light">
                    <Dropdown.Item onClick={this.handleClick}>Compare Change&hellip;</Dropdown.Item>
                </DropdownButton>
            </td>
        </tr>);
    }
}

const mapStateProps = createSelector(
    ({usersAuditlogs}) => usersAuditlogs,
    function (usersAuditlogs) {
        return {
            logs: usersAuditlogs.paged.map((id)=> (
                usersAuditlogs.hash[id]
            )),
            pageBy: usersAuditlogs.pageBy,
            sortBy: usersAuditlogs.sortBy,
            loading: usersAuditlogs.loading,
            filters: usersAuditlogs.filters,
            error: usersAuditlogs.error,
        };
    }
);

@connect(mapStateProps, {
    onSearch: searchLogs,
    onSort: sortLogs,
    onPaginate: paginateLogs,
    setPaginateLogs,
})
class UsersAuditLogsPage extends React.Component {
    static propTypes = {
        logs: PropTypes.arrayOf(PropTypes.any).isRequired,
        filters: PropTypes.array.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSort: PropTypes.func.isRequired,
        onPaginate: PropTypes.func.isRequired,
        sortBy: PropTypes.string,
        pageBy: PropTypes.shape({
            'page': PropTypes.number,
            'page_size': PropTypes.number,
            'total': PropTypes.number,
            'total_pages': PropTypes.number,
            'start_index': PropTypes.number,
            'end_index': PropTypes.number,
        }),
        loading: PropTypes.bool,
        error: PropTypes.any,
        setPaginateLogs: PropTypes.func,
    };

    state = {
        entry: null,
    };

    componentDidMount() {
        const {
            setPaginateLogs,
            onSearch,
            onSort,
            sortBy,
        } = this.props;

        setPaginateLogs({'page': 1, 'page_size': 20});
        if (sortBy) {
            onSearch();
        }
        else {
            onSort('-timestamp');
        }
    }

    handleCompare = (event, entry) => {
        event.preventDefault();
        this.setState({entry});
    };

    handleHide = () => {
        this.setState({entry: null});
    };

    renderLogEntry = (entry) => {
        return (<LogEntry key={entry.id}
            entry={entry}
            onCompare={this.handleCompare} />);
    };

    renderModal() {
        const {entry} = this.state;
        if (!entry) {
            return null;
        }

        return (<DiffModal diff={entry.diff} onHide={this.handleHide} />);
    }

    renderTabs () {
        const currentLogType = 'users';
        return <AuditLogsTabs logsTypes={AUDIT_LOGS_TYPES} selected={currentLogType} />;
    }

    renderTitle () {
        return (
            <div className="pull-left">
                <h3 className="content-header__title">Users Audit Logs</h3>
            </div>
        );
    }

    renderFilters () {
        return (
            <div className="content-header__form">
                <Filters />
            </div>
        );
    }

    renderHeaders () {
        return (
            <div className="content-header content-header--tabs">
                {this.renderTabs()}
                {this.renderTitle()}
                {this.renderFilters()}
            </div>
        );
    }

    render () {
        const logCols = [
            {label: 'ID', w: 65, field: 'id'},
            {label: 'Timestamp', w: 130, field: 'timestamp'},
            {label: 'User', w: 130, field: 'user'},
            {label: 'Affected User > Setting > Channel', w: 350, field: 'section'},
            {label: 'Beginning Value', w: 300},
            {label: 'End Value', w: 300},
            {label: '', w: 65},
        ];
        const {logs, pageBy, sortBy, loading, error, filters} = this.props;
        return (<div className="container-full container-full--top-fix">
            {this.renderModal()}
            {this.renderHeaders()}
            <Tags value={filters} />

            <div className="row-wrapper">
                <CommonTable
                    heads={logCols}
                    onSortBy={this.props.onSort}
                    sortBy={sortBy}
                    emptyLabel="No records."
                    loadingLabel="Records are loading…"
                    errorLabel="Failed to load records."
                    bsStyle="common"
                    loading={loading}
                    error={error} >
                    <tbody>
                        {logs.map(this.renderLogEntry)}
                    </tbody>
                </CommonTable>
                <div className="table-common-footer">
                    <Pager {...pageBy} onChange={this.props.onPaginate} />
                </div>
            </div>
            <PageTitle>Audit Logs</PageTitle>
        </div>);

    }
}

export default UsersAuditLogsPage;
