import {UserAutocomplete} from 'midoffice/data/ManagedTravelerForm';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

function isUsersAuditLogsEnabled() {
    return getFeatureFlag(null,'ENABLE_USER_AUDIT_LOGS', {canGetFromCookie: true});
}

function getPath() {
    return isUsersAuditLogsEnabled() ? '/api/audit-logs/entities/affected_user_autocomplete/' : '/api/audit-logs/affected_user_autocomplete/';
}

export default class AffectedUserAutocomplete extends UserAutocomplete {
    constructor() {
        super({url: getPath()});
    }

    query(query) {
        return super.query(query, {forUser: 0, forCompanies: null});
    }

    parseResponse(data) {
        return data.map((user)=> {
            const label = user.email;
            return {value: user.id, label};
        });
    }
}
