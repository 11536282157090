import moment from 'moment';
import memoize from 'lodash/memoize';
import settings from 'airborne/settings';

const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const serializeDatetime = (datetime)=> (
    moment(datetime).format(API_DATETIME_FORMAT)
);

export const parseDatetime = (datetime)=> (
    moment(datetime, API_DATETIME_FORMAT).format()
);

export const getDateLimits = memoize(() =>  {
    return {
        min: moment(settings.minCheckinDate, 'MM/DD/YYYY'),
        max: moment(settings.maxCheckinDate, 'MM/DD/YYYY'),
    };
});
