import {createSelector} from 'reselect';
import {getFareGroups, getFilteredFareGroups} from './fareGroups';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import union from 'lodash/union';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import gettext from 'airborne/gettext';
import flow from 'lodash/flow';
import uniqBy from 'lodash/fp/uniqBy';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import {getAirFilters, getFiltersExclusions} from './fareGroups';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {
    applyAirFilters,
    getDurationRangeFromFOs,
    getLongFlightDurationByRange,
    getFOfromGroups,
    getFiltersWithInfo,
    calcPriceRange,
    createStopsChoices,
    swapLocations,
    createAirlineChoicesForFares,
    createAllianceChoices,
} from 'airborne/air/fare_search/helpers';
import settings from 'airborne/settings';
import {FARE_TYPES, PREFERRED_AIRLINES_CHOICES} from 'airborne/air/fare_search/helpers';
import {BCD_PREFERRED_LABEL, COMPANY_PREFERRED_LABEL} from 'airborne/air/fare_search/Filters/filterLabels';
import {isPreferredByKey} from 'airborne/air/fare_search/types';

const COUNTABLE_FILTERS = [
    'airlines',
    'alliances',
    'cabin',
    'stops',
    'fareType',
    'baggage',
    'providers',
];

const RANGES_AND_NUMBERS = [
    'price',
    'time',
    'flightNumber'
];

/** Counters Magic
 * *We need to calculate counters based on data filtered out by other filters.*
 * So for each filter with counter we need to filter ALL data again without this filter
 * To make it a bit faster we `getFilteredFareGroupsByFiltersWithoutCounters`
 * to get filtered data which will remain same across all filters with counters.
 * And then each filter with counters will `applyCountableFilters` without itself
 * and go through already partially filtered data.
 */
const getFilteredFareGroupsByFiltersWithoutCounters = createSelector(
    [getFareGroups, getAirFilters, getFiltersExclusions],
    (fareGroups, filters, exclusions) =>
        applyAirFilters(fareGroups, omit(filters, COUNTABLE_FILTERS), exclusions)
);

// Same magic here :)
const getFilteredFareGroupsWithoutRangesAndNumbers = createSelector(
    getFareGroups,
    getAirFilters,
    getFiltersExclusions,
    (fareGroups, filters, exclusions) => applyAirFilters(
        fareGroups,
        omit(filters, RANGES_AND_NUMBERS),
        exclusions,
    )
);

export const getFilteredFareGroupsWithoutPrice = createSelector(
    getFilteredFareGroupsWithoutRangesAndNumbers,
    getAirFilters,
    getFiltersExclusions,
    (fareGroups, filters, exclusions) => applyAirFilters(
        fareGroups,
        omit(filters, RANGES_AND_NUMBERS.filter(f => f === 'price')),
        exclusions,
    )
);

export const getFilteredFareGroupsWithoutTime = createSelector(
    getFilteredFareGroupsWithoutRangesAndNumbers,
    getAirFilters,
    getFiltersExclusions,
    (fareGroups, filters, exclusions) => applyAirFilters(
        fareGroups,
        omit(filters, RANGES_AND_NUMBERS.filter(f => f === 'time')),
        exclusions,
    )
);

export const getFilteredFareGroupsWithoutFlightNumber = createSelector(
    getFilteredFareGroupsWithoutRangesAndNumbers,
    getAirFilters,
    getFiltersExclusions,
    (fareGroups, filters, exclusions) => applyAirFilters(
        fareGroups,
        omit(filters, RANGES_AND_NUMBERS.filter(f => f === 'flightNumber')),
        exclusions,
    )
);

const applyCountableFilters = createSelector(
    getFilteredFareGroupsByFiltersWithoutCounters,
    getAirFilters,
    getFiltersExclusions,
    (_, except) => except,
    (fareGroups, filters, exclusions, except) =>
        applyAirFilters(
            fareGroups,
            pick(filters, COUNTABLE_FILTERS.filter(f => f !== except)),
            exclusions
        )
);


const getAllFlightOptions = createSelector(
    [getFareGroups],
    getFOfromGroups
);

export const getDurationRange = createSelector(
    getAllFlightOptions,
    getDurationRangeFromFOs
);

export const getLongFlightDuration = createSelector(
    getDurationRange,
    getLongFlightDurationByRange
);

export const getAirlinesChoices = createSelector(
    getFareGroups,
    getAllFlightOptions,
    state => state,
    (fareGroups, allFlightOptions, state) => {
        const filteredFares = applyCountableFilters(state, 'airlines');

        return createAirlineChoicesForFares(allFlightOptions, filteredFares);
    }
);

export const getAllianceChoices = createSelector(
    getFareGroups,
    state => state,
    (fareGroups, state) => {
        const filteredFares = applyCountableFilters(state, 'alliances');
        return createAllianceChoices(filteredFares);
    }
);

export const getProviderChoices = state => {
    const groups = applyCountableFilters(state, 'providers');

    return flow(
        filter(({providerType}) => Boolean(providerType)),
        uniqBy('providerType'),
        map((group) => {
            const {providerType} = group;

            return [
                providerType,
                providerType,
                groups.reduce(
                    (acc, inGroup) =>
                        inGroup.providerType === providerType ? acc + 1 : acc, 0
                )
            ];
        })
    )(groups);
};

export const getDestinationCodes = state => {
    const {originDestinations, tripType} = getDestination(state);
    const [firstDestination] = originDestinations;

    return (
        tripType === AIR_TRIP_TYPES.ROUND_TRIP
            ? [firstDestination, swapLocations(firstDestination)]
            : originDestinations
    ).map((OD) => {
        const {pickUp, dropOff} = OD;

        return {
            departure: pickUp.iataCode,
            arrival: dropOff.iataCode,
        };
    });
};

export const getFareGroupCurrency = state =>
    get(getFareGroups(state), '[0].currency');

export const getPriceRange = createSelector(
    getFareGroups,
    calcPriceRange
);

export const getFilteredPriceRange = createSelector(
    getFilteredFareGroupsWithoutPrice,
    calcPriceRange
);

export const getStopsChoices = state => {
    const fareGroups = applyCountableFilters(state, 'stops');

    return createStopsChoices(fareGroups);
};

export const getCabinChoices = createSelector(
    getAllFlightOptions,
    state => state,
    (options, state) => {
        const filteredOptions = getFOfromGroups(applyCountableFilters(state, 'cabin'));
        const getCabinLabel = cabin => settings.CABIN_CLASSES[cabin];

        return union.apply(null, options.map(({cabins}) => cabins)).filter(cabin => cabin).map(cabin => [
            cabin,
            getCabinLabel(cabin),
            filteredOptions.reduce(
                (acc, {cabins}) => cabins.includes(cabin) ? acc + 1 : acc,
                0
            )
        ]);
    }
);


export const getBaggageChoices = createSelector(
    getFilteredFareGroups,
    groups => [[
        true,
        gettext('With Baggage'),
        groups.reduce((acc, {withBaggage}) => withBaggage ? acc + 1 : acc, 0)
    ]]
);

export const getPreferredAirlinesChoices = state => {
    const filteredGroups = applyCountableFilters(state, 'fareType');

    const acc = {
        companyPreferred: 0,
        bcdPreferred: 0,
    };

    filteredGroups.forEach(group =>
        group.originDestinations.forEach(OD =>
            OD.flightOptions?.forEach(({segments}) => {
                acc.companyPreferred += isPreferredByKey(segments, 'preferredAirline') ? 1 : 0;
                acc.bcdPreferred += isPreferredByKey(segments, 'bcdPreferredAirline') ? 1 : 0;
            })
        )
    );

    return [
        [PREFERRED_AIRLINES_CHOICES.COMPANY_PREFERRED, COMPANY_PREFERRED_LABEL, acc.companyPreferred],
        [PREFERRED_AIRLINES_CHOICES.BCD_PREFERRED, BCD_PREFERRED_LABEL, acc.bcdPreferred],
    ];
};

export const getFareTypeChoices = state => {
    const filteredGroups = applyCountableFilters(state, 'fareType');
    const {publ, nego, bcd, company} = filteredGroups.reduce((acc, group) => ({
        publ: group.fareTypes.includes(FARE_TYPES.PUBLISHED) ? acc.publ + 1 : acc.publ,
        nego: group.fareTypes.includes(FARE_TYPES.NEGOTIATED) ? acc.nego + 1 : acc.nego,
        bcd: group.fareTypes.includes(FARE_TYPES.BCD_NEGOTIATED) ? acc.bcd + 1 : acc.bcd,
        company: group.fareTypes.includes(FARE_TYPES.COMPANY_NEGOTIATED) ? acc.company + 1 : acc.company,
    }), {publ: 0, nego: 0, bcd: 0, company: 0});

    return [
        [FARE_TYPES.PUBLISHED, gettext('Published'), publ],
        [FARE_TYPES.NEGOTIATED, gettext('Negotiated'), nego],
        [FARE_TYPES.BCD_NEGOTIATED, gettext('BCD Negotiated'), bcd],
        [FARE_TYPES.COMPANY_NEGOTIATED, gettext('Company Negotiated'), company],
    ];
};

export const getFlightNumberAutocompleteItems = createSelector(
    getFilteredFareGroupsWithoutFlightNumber,
    groups =>
        uniqBy('label')(groups.reduce((acc, {originDestinations}) =>
            originDestinations.reduce((acc, {flightOptions}, ODIndex) =>
                flightOptions.reduce((acc, {segments}) =>
                    [...acc, ...segments.map(({carrier})=> ({
                        label: `${carrier.code}${carrier.flightNumber}`,
                        ODIndex,
                    }))],
                acc),
            acc),
        []))
);

export const getCheckboxFilters = createSelector(
    getAirFilters,
    getStopsChoices,
    getFareTypeChoices,
    getAirlinesChoices,
    getCabinChoices,
    getBaggageChoices,
    getProviderChoices,
    getAllianceChoices,
    (
        filters, stopChoices, fareTypeChoices, airlinesChoices,
        cabinChoices, baggageChoices, providers, allianceChoices,
    ) => {
        const filtersRelations = {
            stops: getFiltersWithInfo(filters, stopChoices, 'stops'),
            fareType: getFiltersWithInfo(filters, fareTypeChoices, 'fareType'),
            airlines: getFiltersWithInfo(filters, airlinesChoices, 'airlines'),
            alliances: getFiltersWithInfo(filters, allianceChoices, 'alliances'),
            cabin: getFiltersWithInfo(filters, cabinChoices, 'cabin'),
            baggage: getFiltersWithInfo(filters, baggageChoices, 'baggage'),
            providers: getFiltersWithInfo(filters, providers, 'providers'),
        };

        return Object.entries(filtersRelations).reduce((acc, [filterKey, values]) =>
            [...acc, ...values.map(value => [filterKey, value])], []
        );
    }
);

export const makeTimeRangeSelector = (ODIndex, juncture) => createSelector(
    getFilteredFareGroupsWithoutTime,
    fareGroups => {
        const junctureMinutesKeys = {'departure': 'departureMinutes', 'arrival': 'arrivalMinutes'};
        const junctureKey = junctureMinutesKeys[juncture];
        const defaultRange = {min: 0, max: 24*60-1};
        if (!fareGroups || !fareGroups.length) return defaultRange;

        return fareGroups.reduce((range, cur) =>
            get(cur, ['originDestinations', ODIndex, 'flightOptions'], [])
                .reduce((optRange, optCur) => {
                    const {[junctureKey]: duration} = optCur;
                    const {min: curMin, max: curMax} = optRange;
                    return {min: Math.min(curMin, duration), max: Math.max(curMax, duration)};
                }, range), {min: Infinity, max: 0});
    }
);

export const makeSelectedTimeRangeSelector = (ODIndex, juncture) => {
    const getTimeRange = makeTimeRangeSelector(ODIndex, juncture);
    return createSelector(
        getAirFilters,
        getTimeRange,
        (filters, timeRange) => {
            const {
                min: initMin = 0,
                max: initMax = 24*60-1
            } = get(filters, ['time', ODIndex, juncture], {});
            const {min: rangeMin, max: rangeMax} = timeRange;

            return {min: Math.max(initMin, rangeMin), max: Math.min(initMax, rangeMax)};
        }
    );
};
