import moment from 'moment';
import uniq from 'lodash/uniq';
import {date} from 'midoffice/helpers/format';
import {diffInDays} from 'airborne/air/fare_search/helpers';
import {gettext, ngettext} from 'airborne/gettext';
import {AIR_TRIP_TYPES} from 'airborne/air/homepage/types';
import flatMap from "lodash/flatMap";

export const getSegmentDepartureTime = segment => {
    const {departure: {datetime}} = segment;
    const format = 'localeTime';

    return date(datetime, format);
};

export const getSegmentArrivalTime = segment => {
    const {arrival: {datetime}} = segment;
    const format = 'localeTime';

    return date(datetime, format);
};

export const getDepartureTime = segments => {
    const {departure: {datetime}} = segments[0];
    const format = 'localeTime';

    return date(datetime, format);
};

export const getArrivalTime = segments => {
    const {arrival: {datetime}} = segments[segments.length - 1];
    const format = 'localeTime';

    return date(datetime, format);
};

export const getCarriers = segments =>
    uniq(segments.map(({carrier, operatingCarrier}) => ({carrier, operatingCarrier})));

export const getDiffInDays = segments => {
    const {departure: {datetime: departureDatetime}} = segments[0];
    const {arrival: {datetime: arrivalDatetime}} = segments[segments.length - 1];
    const departure = moment(departureDatetime);
    const arrival = moment(arrivalDatetime);

    return diffInDays(arrival, departure);
};

export const getStopsText = segments =>
    segments.length > 1
        ? ngettext('{num} Stop', '{num} Stops', segments.length - 1)
        : gettext('Direct');

export const getStopsAirports = segments =>
    segments.slice(1).map(segment => segment.departure.airport.code);

export const getDepartureSegmentCode = segment =>
    segment.departure.airport.code;

export const getArrivalSegmentCode = segment =>
    segment.arrival.airport.code;

export const getDepartureSegmentName = segment =>
    segment.departure.airport.name;

export const getArrivalSegmentName = segment =>
    segment.arrival.airport.name;

export const getFlightDepartureCode = flightOption => {
    const {segments} = flightOption;

    return segments[0].departure.airport.code;
};

export const getFlightArrivalCode = flightOption => {
    const {segments} = flightOption;

    return segments[segments.length - 1].arrival.airport.code;
};

export const getNotSelectedFlightText = (originDestination, tripType) => {
    const {departureIata, arrivalIata, ODIndex} = originDestination;

    if (tripType === AIR_TRIP_TYPES.MULTI_CITY) {
        return gettext('Choose {departureIata}-{arrivalIata} flight…', {departureIata, arrivalIata});
    }

    if (ODIndex === 0) {
        return gettext('Choose a departing ({departureIata}-{arrivalIata}) flight…', {departureIata, arrivalIata});
    }

    return gettext('Choose a return ({departureIata}-{arrivalIata}) flight…', {departureIata, arrivalIata});
};

export const getChangeTextByODIndex = (ODIndex, tripType) => {
    if (tripType === AIR_TRIP_TYPES.MULTI_CITY) {
        return gettext('Change a flight');
    }

    if (ODIndex === 0) {
        return gettext('Change a departing flight');
    }

    return gettext('Change an arriving flight');
};

export const extractSegments = (flightOptions) => {
    return flatMap(flightOptions.filter(Boolean), option => option.segments);
}
