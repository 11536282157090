import {createSelector} from 'reselect';
import take from 'lodash/take';
import takeWhile from 'lodash/takeWhile';
import omit from 'lodash/omit';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import {getCurrentODStep, getFlightOptionsSelection} from './selection';
import {getAirDestValue} from 'airborne/store/modules/confirm_air/selectors';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {
    getPricingFares,
    getSeparatedTicketsCurrentOD, getSeparatedTicketsSelectedFares,
    isAirSeparatedTicketsMode
} from 'airborne/store/modules/pricing_air/selectors';
import {extractEmissionsFromSegments, getUniqueFareKey} from 'airborne/air/fare_search/helpers';
import {extractSegments} from "airborne/air/availability/helpers/flightOptions";

const getAvailabilityFlightOptions = state =>
    state.airAvailability.flightOptions;

export const getAvailabilityFlightOptionsData = state =>
    getAvailabilityFlightOptions(state).data;

export const isAvailabilityLoading = state =>
    getAvailabilityFlightOptions(state).loading;

const getOriginDestinations = state =>
    getAvailabilityFlightOptionsData(state)?.originDestinations;

export const getLightOriginDestinations = createSelector(
    getOriginDestinations,
    (originDestinations) => originDestinations?.map(OD => omit(OD, 'flightOptions'))
);

export const getCurrentFlightOptions = state => {
    const originDestinations = getOriginDestinations(state);
    const ODStep = getCurrentODStep(state);

    return originDestinations?.[ODStep]?.flightOptions;
};

export const istAnyFlightOptions = state =>
    !!getCurrentFlightOptions(state)?.length;

export const getSelectedFlightOptions = createSelector(
    getOriginDestinations,
    getFlightOptionsSelection,
    (originDestinations, selection) => {
        return selection.map((id, index) => {
            if (!id) return null;

            return originDestinations[index]
                .flightOptions.find(flightOption => flightOption.id === id || id === flightOption.flightOptionKey);
        });
    }
);

export const getSelectedFlightOptionByOD = (state, ODIndex) => {
    const flightOptions = getSelectedFlightOptions(state);

    return flightOptions[ODIndex];
};

export const getSelectedFlightOptionEmissions = (state, ODIndex) => {
    const isSeparatedTickets = isAirSeparatedTicketsMode(state);
    const separatedTicketsCurrentOD = getSeparatedTicketsCurrentOD(state);
    // in case of separated tickets we can use only pricing fares for selected ticket in the ui
    const isTicketPricingFaresUnavailable = isSeparatedTickets && ODIndex !== separatedTicketsCurrentOD;

    const pricingFares = isTicketPricingFaresUnavailable ? [] : getPricingFares(state);

    const separatedTicketsSelectedFare = getSeparatedTicketsSelectedFares(state)[ODIndex];
    // we do not store pricing fares for both 2 oneway fares searches at the same time,
    // but we can take second pre-saved fare from store (if a user has already selected one)
    const shouldUseSeparatedTicketSelectedFare = isSeparatedTickets && separatedTicketsSelectedFare !== null;

    if (isEmpty(pricingFares) && !shouldUseSeparatedTicketSelectedFare) {
        return 0;
    }

    const pricingFare = shouldUseSeparatedTicketSelectedFare ? separatedTicketsSelectedFare : head(pricingFares);

    const segments = isSeparatedTickets
        ? head(pricingFare?.originDestinations)?.segments
        : pricingFare?.originDestinations[ODIndex]?.segments;

    if (!segments) {
        return 0;
    }

    return extractEmissionsFromSegments(segments);
};

export const isAllFlightOptionsSelected = (state) => {
    const options = getFlightOptionsSelection(state);

    return !!options.length && options.every(Boolean);
};

export const getSegmentsFromSelectedFlightOptions = createSelector(
    getSelectedFlightOptions,
    (flightOptions) => extractSegments(flightOptions)
);

export const addODsWithoutFlightOptions = (destFormValue, originDestinations = []) => {
    return destFormValue.originDestinations.reduce((acc, OD, ODIndex) => {
        const value = originDestinations[ODIndex] || {
            departureIata: OD.pickUp.iataCode,
            arrivalIata: OD.dropOff.iataCode,
            ODIndex,
            flightOptions: [],
        };
        return [...acc, value];
    }, []);
};

export const getCroppedLightOriginDestinations = createSelector(
    getLightOriginDestinations,
    getFlightOptionsSelection,
    getAirDestValue,
    (originDestinations, selection, destFormValue) => {
        const croppedSelection = takeWhile(
            selection,
            (_val, index, ary) => ary.slice(index).some(Boolean)
        );

        if (destFormValue.tripType === AIR_TRIP_TYPES.MULTI_CITY) {
            originDestinations = addODsWithoutFlightOptions(destFormValue, originDestinations);
        }

        return take(originDestinations, croppedSelection.length + 1);
    }
);

export const getSegmentsLengthInSelectedFlights = createSelector(
    getSelectedFlightOptions,
    (flightOptions) => flightOptions.reduce((acc, flightOption) => {
        const {segments: {length}} = flightOption;

        return acc + length;
    }, 0)
);

export const getCurrentDestionationCodes = (state) => {
    const ODIndex = getCurrentODStep(state);
    const destinations = getLightOriginDestinations(state);
    if (!destinations) return {departure:'', arrival: ''};
    const current = destinations[ODIndex];
    return {departure: current?.departureIata, arrival: current?.arrivalIata};
};
