import * as types from '../actionTypes';
import {getOptionalServiceGroups, getSelectedOptionalServices} from "airborne/store/modules/checkout_air/selectors";
import {getAllServiceGroupKeys} from "airborne/air/checkout/helpers/checkoutForm";

export function patchCheckoutForm({toChange, toRemove}) {
    return {type: types.AIR_PATCH_CHECKOUT_FORM, toChange, toRemove};
}

export function resetCheckoutFormFields(fields) {
    return patchCheckoutForm({toChange: {}, toRemove: fields});
}

export function resetTicketOptionalServices(ticketIndex) {
    return function (dispatch, getState) {
        const ticketSelectedServices = getSelectedOptionalServices(getState(), ticketIndex);
        const allSelectedServices = getSelectedOptionalServices(getState());

        return dispatch(patchCheckoutForm({
            toChange: {
                value: {
                    'optional_services': allSelectedServices.filter(
                        serviceKey => !ticketSelectedServices.includes(serviceKey)
                    )
                }
            }
        }));
    }
}

export function actualizeOptionalServices() {
    return function (dispatch, getState) {
        const serviceGroups = getOptionalServiceGroups(getState());
        const selectedServiceKeys = getAllServiceGroupKeys(serviceGroups);
        const relevantServiceKeys = getSelectedOptionalServices(getState());

        dispatch({type: types.AIR_PATCH_CHECKOUT_FORM, toChange: {
            value: {
                'optional_services': selectedServiceKeys.filter(
                    (serviceKey) => relevantServiceKeys.includes(serviceKey)
                )
            }
        }});
    }
}
