import some from 'lodash/some';
import compact from 'lodash/compact';
import indexOf from 'lodash/indexOf';
import {getItemByIndex} from 'airborne/homepage2/helpers/multipnr';
import {createSelector} from 'reselect';
import identity from 'lodash/identity';
import get from 'lodash/get';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {getStep} from './homepage';
import {getModal} from 'airborne/store/modules/header/selectors/modal';
import {capitalizeEveryWord} from 'airborne/helpers/strings';
import {getProduct} from 'airborne/store/modules/homepage/selectors/product';
import {getRawPnrId} from 'airborne/store/modules/header/selectors/rawpnr';
import {getCarBookingData} from 'airborne/cars/store/confirmation/selectors';
import {getBooking} from 'airborne/store/modules/confirm_air/selectors';
import {getSelectedRate} from 'airborne/cars/checkout/selectors';

const MAX_PNR_COUNT = 9;

export function hasPnrReducer(state) {
    return Boolean(state.multiPnr);
}

export function getPnr(state, idx) {
    return getItemByIndex(state.multiPnr.items, idx);
}

export function isPnr(state) {
    return Boolean(getPnr(state, 0).profile.pnr);
}

export function getAllProfiles(state) {
    return state.multiPnr.items.map(({profile})=> profile);
}

export const getAllPnrs = createSelector(
    getAllProfiles,
    (profiles)=> profiles.filter(identity).map(({pnr})=> pnr).filter(identity),
);

export const getPnrIndex = createSelector(
    getAllPnrs,
    (state, pnr) => pnr,
    (pnrs, pnr) => indexOf(pnrs, pnr),
);

export function getProfilesCount(state) {
    return getAllPnrs(state).length;
}

export function getPnrCount(state) {
    return get(state, 'multiPnr.items').length;
}

export function getPnrProfile(state, idx) {
    const profile = getPnr(state, idx).profile;

    if (!profile || !profile.company_name) {
        return profile;
    }

    return {
        ...profile,
        'company_name': capitalizeEveryWord(profile.company_name),
    };
}

export function getPnrByProduct(state) {
    const product = getProduct(state);
    const airPnrId = getBooking(state)?.pnr?.id;
    const carsPnrId = getCarBookingData(state)?.pnr?.id;

    if (product === 'air' && airPnrId) {
        return airPnrId;
    }
    if (product === 'cars' && carsPnrId) {
        return carsPnrId;
    }
    return getRawPnrId(state);
}

export function getRawPnr(state, idx) {
    return getPnr(state, idx).raw;
}

export function getPnrRetrieveErrors(state) {
    return get(state, 'multiPnr.retrieveErrors');
}

export function getPnrErrors(state) {
    const retrieveErrors = getPnrRetrieveErrors(state);
    const isPermissionError = Boolean(get(retrieveErrors[0],'error.errors[0]', false));

    if (retrieveErrors.length  === 0) {
        return get(state, 'multiPnr.errors');
    }

    if (retrieveErrors.length  === 1 && getStep(state) === 'pnr' && !isPermissionError) {
        const {pnr, error} = retrieveErrors[0];
        const {pnr: pnrMessage, form} = error;
        return dropEmpty({
            pnr: pnrMessage,
            form: form ? [{pnr, message: form}] : null,
        });
    }

    if (retrieveErrors.length === 1 && getStep(state) === 'pnr' && isPermissionError) {
        const {error: {errors}} = retrieveErrors[0];
        const {message: form} = errors[0];
        return dropEmpty({
            form: form ? [{message: form}] : null,
        });
    }

    return {
        form: retrieveErrors.map(
            ({pnr, error}) => {
                const {form, pnr: pnrMessage} = error;
                return {pnr, message: pnrMessage || form};
            },
        ),
    };
}

export function getPnrOid(state) {
    return get(state, 'multiPnr.oid');
}

export function getCompanySmid(state) {
    return get(state, 'dest.options.smid');
}

export function getCarsPaymentPriority(state) {
    const defaultPaymentsList = get(state, 'dest.options.cars_payment_methods_priority');
    const paymentsListByVendors = get(state, 'dest.options.cars_payment_methods_by_vendors') || [];
    const vendorCode = getSelectedRate(state)?.vendor?.code;
    const rule = paymentsListByVendors.find((rule) => {
        return rule.vendor_codes.includes(vendorCode);
    });
    return rule ? rule['payment_methods'] : defaultPaymentsList;
}

export function getHotelsPaymentPriority(state) {
    return get(state, 'dest.options.payment_methods_priority');
}

export function getPnrLoading(state) {
    return get(state, 'multiPnr.loading');
}

export function getPnrForm(state) {
    const data = get(state, 'multiPnr.form');

    if (!data || !data.company) {
        return data;
    }

    const {company, company: {label}} = data;

    return {
        ...data,
        company: {
            ...company,
            label: label && capitalizeEveryWord(label),
        },
    };
}

export function getCompany(state) {
    return get(state, 'multiPnr.form.company');
}

export function getEntityId(state) {
    return getCompany(state).value;
}

export function getPrevBookingId(state, idx) {
    return getPnr(state, idx).previousBookingId;
}

export function isModification(state) {
    return Boolean(some(state.multiPnr.items.map(
        (item)=> item.previousBookingId
    )));
}

export function getEditablePnrIndex(state) {
    return get(state, 'multiPnr.editPnr');
}

export function getEditablePnr(state) {
    const index = getEditablePnrIndex(state);
    if (index === null) {
        return null;
    }
    return getPnrProfile(state, index);
}

export function getHomepagePnrIndex(state) {
    const editIndex = getEditablePnrIndex(state);
    return  editIndex === null ? (getPnrCount(state) - 1) : editIndex;
}

export function getTspmName(profile={}) {
    const {
        'tspm_salutation': salutation,
        'tspm_first_name': fName,
        'tspm_last_name': lName
    } = profile;

    return compact([
        salutation,
        fName,
        lName,
    ]).join(' ') || null;
}

export function getPnrName(profile={}) {
    const {
        'pnr_name_title': title,
        'pnr_name_first': firstName,
        'pnr_name_last': lastName,
    } = profile;

    return compact([title, firstName, lastName]).join(' ') || null;
}

export function getProfileName(profile) {
    return profile
        ? getPnrName(profile) || getTspmName(profile)
        : null;
}

export function isNoPnr(state) {
    const firstProfile = getPnrProfile(state, 0);
    return Boolean(firstProfile) && !firstProfile.pnr;
}

export function isMaxPnrCount(state) {
    return getPnrCount(state) === MAX_PNR_COUNT;
}

export function getMaxPnrCount() {
    return MAX_PNR_COUNT;
}

export function hasPnrs(state) {
    return getAllPnrs(state).length > 0;
}

export function isSearchPnrEditor(state) {
    return get(state, 'multiPnr.searchPnrEditor')
        || getModal(state).kind === 'pnrSearch' && !hasPnrs(state);
}

export function isAddPnr(state) {
    return hasPnrs(state) && getStep(state) === 'pnr' || isSearchPnrEditor(state);
}

export function isMultiPnr(state) {
    return getPnrCount(state) > 1;
}

export function hasGuestTraveler(state) {
    return some(
        getAllProfiles(state),
        (profile)=> profile && profile['tspm_traveler_id'] === null
    );
}
