import 'airborne/cars/confirmation/types';

function initial() {
    return {cancellationStatus: null, cancellationConfirmationNumber: null,};
}

export default function carCancelAtModifyFlow(state, {type, cancellationStatus, cancellationNumber}) {
    if (!state) {
        return initial();
    }
    if (type === 'CARS_MODIFY_CANCEL') {
        return {
            ...initial(),
            cancellationStatus,
            cancellationConfirmationNumber: cancellationNumber
        };
    }
    if (type === 'CARS_MODIFY_CANCEL_FAIL') {
        return {
            ...initial(),
            cancellationStatus: 'failed',
            cancellationConfirmationNumber: cancellationNumber
        };
    }
    return state;
}
