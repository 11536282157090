import React from 'react';
import {Route, Redirect} from 'react-router-dom';

import AuditLogsPage from 'midoffice/auditlogs/AuditLogsPage';
import UsersAuditLogsPage from 'midoffice/auditlogs/usersAuditlogs/UsersAuditLogsPage';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';


let auditLogsRoutes = [<Route path="/audit-logs/" component={AuditLogsPage} key="audit" />];

if (getFeatureFlag(null,'ENABLE_USER_AUDIT_LOGS', {canGetFromCookie: true})) {
    auditLogsRoutes = [
        <Route exact path="/audit-logs/" key="redirect-auditlogs">
            <Redirect to="/audit-logs/entities/" />
        </Route>,
        <Route path="/audit-logs/users/" component={UsersAuditLogsPage} key="users-audit" />,
        <Route path="/audit-logs/entities/" component={AuditLogsPage} key="entities-audit" />,
    ];
}

export default auditLogsRoutes;
