import {createSelector} from 'reselect';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import {DECK_CODES, getUniqueFareKey, SEAT_STATUSES} from 'airborne/air/fare_search/helpers';
import {getFareGroupStoreKey, getSeparatedTicketsFareKeys} from 'airborne/store/modules/search_air/selectors/fareKeys';
import {isAirSeparatedTicketsMode} from "airborne/store/modules/pricing_air/selectors";

const isAnyOfSeatsAvailable = decks =>
    Boolean(decks?.find(({seatsMatrix}) =>
        find(flatten(seatsMatrix), seat =>
            seat?.info?.status?.code === SEAT_STATUSES.A
        )
    ));

export const getSeatMap = state => state.air.seatMap;
export const getSeatMapRequests = state => getSeatMap(state).requests;
const getSeatMapData = state => getSeatMap(state).data;

export const getSelectedSeatMaps = createSelector(
    getSeatMapData,
    getFareGroupStoreKey,
    (seatMapData, key) => seatMapData[key]
);

const getSeatMapsSeparatedTickets = createSelector(
    getSeatMapData,
    getSeparatedTicketsFareKeys,
    (seatMapData, fareKeys) => fareKeys.flatMap(fareKey => seatMapData[fareKey])
);

export const isSeatMapAvailable = createSelector(
    getSelectedSeatMaps,
    isAirSeparatedTicketsMode,
    getSeatMapsSeparatedTickets,
    (selectedSeatMaps, isSeparatedTickets, seatMapsSeparatedTickets) => {
        const seatMaps = isSeparatedTickets ? seatMapsSeparatedTickets : selectedSeatMaps;
        return Boolean(seatMaps?.some(seatMap => seatMap ? isAnyOfSeatsAvailable(seatMap.decks) : false));
    }
);

export const getSelectedSeatMapBySegment = createSelector(
    // passing "state" only argument directly to avoid "segmentIdRef" passing as getFareKeys "ticketIndex" parameter
    (state, ticketIndex) => getSelectedSeatMaps(state, ticketIndex),
    (_, ticketIndex, segmentIdRef) => segmentIdRef,
    (selectedSeatMaps, segmentIdRef) =>
        selectedSeatMaps?.find(
            seatMap => seatMap.segment.segmentIdRef === segmentIdRef
        )
);

export const isSeatMapAvailableBySegment = createSelector(
    getSelectedSeatMapBySegment,
    selectedSeatMap => isAnyOfSeatsAvailable(selectedSeatMap?.decks)
);

export const getSeatMapErrors = state => getSeatMap(state).errors;

export const isSeatMapLoading = state => getSeatMap(state).loading;

// ===== DYNAMIC SELECTORS =====

const getSeatMapByKeys = (state, fareGroupKey, flightOptionKeys) => {
    const seatMapData = getSeatMapData(state);
    const storeKey = getUniqueFareKey({fareGroupKey, flightOptionKeys});

    return seatMapData[storeKey];
};

export const isSeatMapLoadedByKeys = (state, fareGroupKey, flightOptionKeys) =>
    Boolean(getSeatMapByKeys(state, fareGroupKey, flightOptionKeys));

export const getDecksLocationsBySegment = (state, ticketIndex = 0, segmentIdRef) => {
    const seatMap = getSelectedSeatMapBySegment(state, ticketIndex, segmentIdRef);

    // Default case when deck has no location
    const defaultLocation = {code: DECK_CODES.M, description: 'Main Deck'};

    return uniqBy(
        seatMap?.decks?.map(deck => deck.location || defaultLocation),
        location => location.code
    );
};

export const getDecksBySegmentAndDeckCode = (state, segmentIdRef, deckCode, ticketIndex = 0) => {
    const seatMap = getSelectedSeatMapBySegment(state, ticketIndex, segmentIdRef);
    const {decks} = seatMap;

    return decks?.filter(deck => {
        return (
            // Default case when deck has no location
            (!deck.location && (!deckCode || deckCode === DECK_CODES.M)) ||
            deck?.location?.code === deckCode
        );
    });
};
