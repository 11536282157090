import moment from 'moment';
import memoize from 'lodash/memoize';

import settings from 'airborne/settings';

export const getDateLimits = memoize(() =>  {
    return {
        min: moment(settings.minCheckinDate, 'MM/DD/YYYY'),
        max: moment(settings.maxCheckinDate, 'MM/DD/YYYY'),
    };
});

export const isCloseCheckin = ({min} = {}) => {
    if (!min) {
        return false;
    }

    const minDate = moment(settings.minCheckinDate, 'MM/DD/YYYY').add(1, 'days');
    const checkin = moment(min, 'YYYY/MM/DD');

    return checkin.isSameOrBefore(minDate);
};
