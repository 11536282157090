import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import set from 'lodash/fp/set';
import intersection from 'lodash/intersection';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';

import {
    getVendors, getNegotiatedChoices
} from 'airborne/cars/homepage/helpers/carVendor';
import {fixDistance, minDistance} from 'airborne/homepage2/helpers/searchDistance';
import {AIR_SEARCH_TYPES} from 'airborne/homepage2/types';
import settings from 'airborne/settings';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {dropEmpty} from 'midoffice/helpers/urlParams';

export const mergeIdNumbers = (valueIdNumbers, idNumbersToMerge) => {
    const mergedNumbers = uniqBy([
        ...(idNumbersToMerge || []),
        ...(valueIdNumbers || [])
    ], 'vendor');

    return mergedNumbers
        .map(({vendor, idNumber, customIdInput, number}) => {
            const isCustomIdNumber = idNumber === 'custom' || Boolean(number) || Boolean(customIdInput);

            return dropEmpty({
                vendor,
                idNumber: isCustomIdNumber ? 'custom' : idNumber,
                customIdInput: number || customIdInput,
            });
        })
        .filter(number => Boolean(number.vendor));
};

const INITIAL_HOTELS_VALUE = {guests: 1, distance: minDistance(null)};
const INITIAL_CARS_VALUE = {
    acriss: [],
    pickUpDistance: minDistance(null),
    dropOffDistance: minDistance(null),
    differentDropOff: false,
    idNumbers: [{}],
    cdNumbers: [{}],
    itNumbers: [{}],
};


export const INITIAL_DEPARTURE_CABIN = 'economy';
export const INITIAL_ARRIVAL_CABIN = 'same';
export const INITIAL_ORIGIN_DESTINATION_VALUE = {
    noCabinPreference: true,
    departureCabin: INITIAL_DEPARTURE_CABIN,
    arrivalCabin: INITIAL_ARRIVAL_CABIN,
    timeRestrictionOutbound: {time: '', timeWindow: 2},
    timeRestrictionInbound: {time: '', timeWindow: 2},
};
export const INITIAL_AIR_VALUE = {
    tripType: 'roundTrip',
    directFlights: false,
    ftNumbers: [],
    specificFlights: [],
    originDestinations: [INITIAL_ORIGIN_DESTINATION_VALUE],
};


function initial() {
    return {
        hotels: {
            value: INITIAL_HOTELS_VALUE,
            errors: null,
        },
        cars: {
            value: INITIAL_CARS_VALUE,
            errors: null,
        },
        air: {
            value: INITIAL_AIR_VALUE,
            errors: null,
        },
        rail: {
            value: {},
            errors: null,
        },
        loading: false,
        options: {
            'special_rates': null,
            'chains': null,
            'search_limit': 100,
            'ads': {},
            'is_guest_traveler_enabled': null,
            'per_diems_enabled': null,
            'rate_labeling': {},
            'primary_gds': null,
        },
        optionsParams: {
            land: null,
            'configuration_id': null,
        },
        product: 'hotels',
        prevProduct: null,
        view: 'list',
        collapsableSections: {},
        airSearchType: AIR_SEARCH_TYPES.AVAILABILITY,
    };
}

function pickFields(value) {
    if (value.expired === true) {
        return ['destination', 'chains', 'hotel_name'];
    }
    if (value.expired === false) {
        return ['destination', 'dates', 'chains', 'hotel_name'];
    }

    return ['destination'];
}

function reuseHotel({hotels: {value, errors, ...rest}, ...state}, newValue) {
    const fields = pickFields(newValue);
    return set('hotels', {
        ...rest,
        value: {
            ...value,
            ...pick(newValue, fields),
            distance: fixDistance(
                newValue.destination,
                newValue.distance || value.distance,
            ),
        },
        errors: errors ? omit(errors, fields) : null,
    }, state);
}

function pickCarFields(value) {
    if (value.expired === true) {
        return ['pickUp', 'dropOff', 'differentDropOff', 'acriss'];
    }
    if (value.expired === false) {
        return ['pickUp', 'dropOff', 'dates', 'differentDropOff', 'acriss'];
    }

    return ['pickUp', 'dropOff'];
}

function reuseCar({cars: {value, errors, ...rest}, ...state}, newValue) {
    const fields = pickCarFields(newValue);

    return {
        ...state,
        cars: {
            ...rest,
            value: {
                ...value,
                ...pick(newValue, fields),
                pickUpDistance: Math.max(
                    newValue.pickUpDistance || value.pickUpDistance,
                    minDistance(newValue.pickUp)
                ),
                dropOffDistance: Math.max(
                    newValue.dropOffDistance || value.dropOffDistance,
                    minDistance(newValue.dropOff)
                ),
            },
            errors: errors ? omit(errors, fields) : null,
        },
    };
}

function reuseAir({air: {value, errors, ...rest}, ...state}, newValue) {
    const {expired, originDestinations} = newValue;
    if (expired) {
        newValue = {
            ...newValue,
            originDestinations: originDestinations.map(OD => ({
                ...OD,
                dateRange: null
            }))
        };
    }

    const fields = [
        'originDestinations',
        'directFlights',
        'allowedBaggage',
        'refundableOnly',
        'tripType',
        'excludePenalties',
        'changeableOnly',
        'airlineCodes',
        'airlineAlliances',
        'connections',
        ...(expired ? [] : ['dateRange']),
        'ftNumbers',
    ];
    return {
        ...state,
        air: {
            ...rest,
            value: {
                ...value,
                ...pick(newValue, fields),
            },
            errors: errors ? omit(errors, fields) : null,
        }
    };
}

const REUSE_HANDLERS = {
    'hotels': reuseHotel,
    'cars': reuseCar,
    'air': reuseAir
};

function reuse(state, newValue) {
    const handler = REUSE_HANDLERS[state.product];
    return handler(state, newValue);
}

function prepopulateIdNumbers(state, tspmIdNumbers) {
    const idNumbersSso = state.cars.value.idNumbers;

    const {options: {'primary_gds': gds}} = state;
    const VALID_VENDORS = Object.keys(getVendors(gds) || {});

    const tspmVendors = intersection(
        Object.keys(tspmIdNumbers || {}), VALID_VENDORS);
    const idNumbersTspm = tspmVendors.map((vendor)=> ({
        vendor, number: tspmIdNumbers[vendor][0]
    }));

    const idNumbers = [
        ...differenceBy(idNumbersSso, idNumbersTspm, 'vendor'),
        ...idNumbersTspm,
    ].filter(({number}) => number);

    if (idNumbers.length) {
        if (!getFeatureFlag(state, 'ENABLE_COMPANY_ID_FIELD')) {
            return set('cars.value.idNumbers', idNumbers, state);
        }

        const valueIdNumbers = state.cars.value.idNumbers;
        const preparedIdNumbers = mergeIdNumbers(valueIdNumbers, idNumbers);

        return set('cars.value.idNumbers', preparedIdNumbers, state);
    }
    return state;
}

const prepopulateFtNumbers = (state, ftNumbers) => ftNumbers ? set(
    'air.value.ftNumbers',
    Object
        .keys(ftNumbers)
        .filter(vendor => {
            return settings.AIRLINES[vendor];
        })
        .map(vendor => ({vendor, number: ftNumbers[vendor][0]})),
    state,
) : state;

function getCompanyNumber(vendor, choices) {
    if (choices[vendor].length === 1) {
        const firstChoice = choices[vendor][0];
        const vendorCodeOfFirstChoice = firstChoice[0];
        return vendorCodeOfFirstChoice;
    }
    const filteredCompany = choices[vendor].filter(negotiatedNumber => !negotiatedNumber[2]);
    const firstFilteredCompany = filteredCompany[0];
    const vendorOfTheFirstCompany = firstFilteredCompany ? firstFilteredCompany[0] : null;
    return vendorOfTheFirstCompany;
}
function getAgencyNumber(vendor, choices) {
    if (choices[vendor].length === 1) {
        return null;
    }
    const filteredAgency = choices[vendor].filter(negotiatedNumber => negotiatedNumber[2]);
    const firstFilteredAgency = filteredAgency[0];
    const vendorOfTheFirstAgency = firstFilteredAgency ? firstFilteredAgency[0] : null;
    return vendorOfTheFirstAgency;
}

function prepopulateNegotiatedNumbers(negotiatedCodes) {
    const negotiatedNumbers = Object.keys(negotiatedCodes).map((vendor)=> ({
        vendor,
        number:  getCompanyNumber(vendor, negotiatedCodes),
        agencyNumber: getAgencyNumber(vendor, negotiatedCodes),
    }));

    if (negotiatedNumbers.length === 0) {
        return [{}];
    }
    return negotiatedNumbers;
}

function preselectRates(oldValue, {'special_rates': rates=[]}) {
    return {
        ...oldValue,
        'special_rates': (rates || [])
            .filter(([, , preselect])=> preselect)
            .map(([code])=> code),
    };
}

function setOptions(state, options, params) {
    const value = preselectRates(state.hotels.value, options);
    return {
        ...state,
        options,
        optionsParams: params,
        hotels: {
            ...state.hotels,
            value,
        },
    };
}

function setDefaultDestinations(state, {hotels, cars}) {
    return {
        ...state,
        hotels: {
            value: {...state.hotels.value, ...hotels},
            errors: null,
        },
        cars: {
            value: {...state.cars.value, ...cars},
            errors: null,
        },
        loading: false,
    };
}

export default function destination(
    state,
    {type, value, errors, destination, product, profile, field, fieldState, searchType, params}
) {
    if (!state) {
        return initial();
    }

    if (type === 'TRAVELER_PROFILE_CHANGED' && profile) {
        const stateId = prepopulateIdNumbers(state, profile['cars_id_numbers']);
        return prepopulateFtNumbers(stateId, profile['ft_numbers']);
    }

    if (type === 'LOADED_PNR' && profile) {
        const stateWithIds = prepopulateIdNumbers(state, profile['tspm_cars_id_numbers']);
        return prepopulateFtNumbers(stateWithIds, profile['tspm_ft_numbers']);
    }

    if (type === 'CHANGE_DEST') {
        return {
            ...state,
            [state.product]: {
                value,
                errors,
            }
        };
    }

    if (type === 'CHANGE_PRODUCT') {
        return {
            ...state,
            product,
        };
    }

    if (type === 'CHANGE_DEST_ERRORS') {
        const product = state.product;
        return {
            ...state,
            errors,
            [product]: {
                ...state[product],
                errors,
            }
        };
    }

    if (type === 'REUSE_DEST') {
        return reuse(state, value);
    }

    if (type === 'BOOKING_FAIL') {
        return {...state, loading: false};
    }

    if (type === 'REUSE_SEARCH') {
        return setDefaultDestinations(state, value);
    }

    if (type === 'SEARCH_DESTINATION_LABEL') {
        return {
            ...state,
            hotels: {
                ...state.hotels,
                value: destination,
            },
        };
    }

    if (type === 'DESTINATION_OPTIONS') {
        state = setOptions(state, value, params);
        return {
            ...state,
            cars: {
                value: {
                    ...state.cars.value,
                    itNumbers: prepopulateNegotiatedNumbers(getNegotiatedChoices(value, 'cars_it_numbers')),
                    cdNumbers: prepopulateNegotiatedNumbers(getNegotiatedChoices(value, 'cars_negotiated_rates')),
                },
                errors: null,
            }
        };
    }

    if (type === 'DISCARD_PNR') {
        return setDefaultDestinations(initial(), value);
    }

    if (type === 'NAVIGATE' && destination) {
        return {
            ...state,
            errors: null,
            hotels: {
                value: destination,
                errors: null,
            }
        };
    }

    if (type === 'SEARCH_MAP') {
        return {...state, view: 'map'};
    }

    if (type === 'SEARCH_LIST') {
        return {...state, view: 'list'};
    }

    if (type === 'BOOK_CARS_FOR_DESTINATION') {
        return {
            ...state,
            view: 'list',
            product: 'cars',
            prevProduct: state.product,
            cars: {
                value: {...state.cars.value, ...value},
                errors: null,
            },
        };
    }

    if (type === 'BOOK_PRODUCT_FROM_CONFIRMATION') {
        const updatedValue = product === 'air' ? INITIAL_AIR_VALUE :
            {...state[product].value, ...value};
        return {
            ...state,
            view: 'list',
            product: product,
            prevProduct: state.product,
            [product]: {
                value: updatedValue,
                errors: null,
            },
        };
    }

    if (type === 'CLEANUP_PREV_PRODUCT') {
        return {...state, prevProduct: null};
    }

    if (type === 'DEST_TOGGLE_COLLAPSABLE_SECTIONS') {
        return {
            ...state,
            collapsableSections: {
                ...state.collapsableSections,
                [field]: fieldState,
            }
        };
    }

    if (type === 'AIR_CHANGE_SEARCH_TYPE') {
        return {
            ...state,
            airSearchType: searchType,
        };
    }

    return state;
}
