import {dropEmptyDeep} from 'airborne/air/shared/helpers/emptyDeep';
import {getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import {locationCodes} from './destinations';
import {AIR_TRIP_TYPES} from 'airborne/air/homepage/types';

function serializeConnections(connection) {
    if (!connection) {
        return null;
    }

    const {iataCode, label, type} = connection;

    return [
        {
            'location_code': iataCode,
            'location_name': label,
            'location_type': locationCodes[type],
        }
    ];
}

function extractLocationLabel(destinations, index, tripType) {
    const isRoundTrip = tripType === AIR_TRIP_TYPES.ROUND_TRIP;
    const {pickUp, dropOff} = destinations[isRoundTrip ? 0 : index];
    const labels = [pickUp.label, dropOff.label];

    if (isRoundTrip && index > 0) {
        return labels.reverse();
    }

    return labels;
}

export function injectRecentSearchesParams(state, search) {
    const {originDestinations, tripType, connections} = getDestFormValue(state);

    const destinations = search['origin_destinations'].map((serializedDest, index) => {
        const [pickUpLabel, dropOffLabel] = extractLocationLabel(originDestinations, index, tripType);

        return dropEmptyDeep({
            ...serializedDest,
            'departure_location': {
                ...serializedDest['departure_location'],
                'location_name': pickUpLabel,
            },
            'arrival_location': {
                ...serializedDest['arrival_location'],
                'location_name': dropOffLabel,
            },

            // *Rename* field for connections in recent searches
            'connecting_locations': serializeConnections(connections?.[index]),
            'connecting_locations_codes': null,
        });
    });

    return {
        ...search,
        'origin_destinations': destinations,
    };
}
