import slice from 'lodash/slice';

import {orderInline} from 'midoffice/helpers/paginate';
import {applyFilters} from 'airborne/cars/search/helpers/filters';
import {comparePreferences, preferenceOrder} from 'airborne/search2/helpers/preference';

import {PICKUP_DISTANCE} from 'airborne/cars/search/filters/CarSorting';


export function paginateRates(rates, pagination) {
    let total = rates.length;
    let startIndex = (pagination.page - 1) * pagination.pageSize;
    let endIndex = Math.min(startIndex + pagination.pageSize, total);

    return slice(rates, startIndex, endIndex);
}

export function orderRates(rates, orderBy, numberFields = [PICKUP_DISTANCE]) {
    if (preferenceOrder(orderBy)) {
        return rates.slice().sort((a,b) => comparePreferences(a,b, orderBy.startsWith('-')));
    }
    return orderInline(rates.slice(), orderBy,null, numberFields);
}

export function filterRates(rates, filters) {
    return applyFilters(rates, filters);
}
