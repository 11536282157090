import gettext from 'airborne/gettext';
import pick from 'lodash/pick';
import find from 'lodash/find';
import some from 'lodash/fp/some';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import emailValidator from 'midoffice/newforms/validators/email';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {
    getCheckoutData,
    getCheckoutForm,
    getPaymentOptions,
    getPlaceOfBirth,
    getTspmCards,
    getVpaCardPools,
} from 'airborne/store/modules/checkout_air/selectors';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import settings from 'airborne/settings';
import {
    idCardFormFields,
    manualPassportFormFields,
    visaDraftFormFields,
} from 'airborne/air/checkout/TravelDocumentation/PassportSchema';
import moment from 'moment';
import {pad} from 'airborne/air/checkout/helpers/payment';

export const AIR_CARDS = ['VI', 'AX', 'MC', 'DS', 'JC', 'DC', 'TP'];

const CHECKOUT_DATA_FIELDS = [
    'first_name',
    'middle_name',
    'last_name',
    'gender',
    'date_of_birth',
    'email',
    'phone',
    'vpa_card_pools',
    'salutation',
    'title',
    'suffix',
];

export const GENDER_CHOICES = {
    'M': gettext('Male'),
    'F': gettext('Female'),
    'U': gettext('Undisclosed'),
    'X': gettext('Unspecified'),
};

export const SALUTATION_CHOICES = [
    ['MISS', gettext('MISS')],
    ['MR', gettext('MR')],
    ['MRS', gettext('MRS')],
    ['MS', gettext('MS')],
    ['MX', gettext('MX')],
];

export const TITLE_CHOICES = [
    ['DR', gettext('DR')],
    ['PROF', gettext('PROF')],
];

export const TRAVELER_NUMBER_COUNTRIES = {
    'US': 'US',
    'CA': 'CA'
};

export function prefillCrd(value, question) {
    return value.hasOwnProperty(question.code)
        ? value
        : {...value, [question.code]: question.default || null};
}

function pickPnrFields(pnr) {
    return dropEmpty({
        'first_name': pnr['pnr_name_first'] || null,
        'last_name': pnr['pnr_name_last'] || null,
        'email': pnr['email'] || null,
    });
}

function prefillManualCard(value) {
    return {
        'credit_card_first_name': value['first_name'],
        'credit_card_last_name': value['last_name'],
    };
}

function prefillTspmCard(tspmCards, cardIndex) {
    const card = find(tspmCards, {cardIndex});
    return {
        'card_index': card.cardIndex,
        'credit_card_expiration': {
            year: Number(card.cardYear),
            month: Number(card.cardMonth),
        },
        'credit_card_first_name': card.firstName,
        'credit_card_last_name': card.lastName,
    };
}

function prefillCreditCard(value, state) {
    const tspmCards = getTspmCards(state);
    const tspmCardsPresent = tspmCards && tspmCards.length;
    let cardIndex = value && value.card_index;
    if (!value.hasOwnProperty('card_index')) {
        cardIndex = tspmCardsPresent ? tspmCards[0].cardIndex : null;
    }
    return (tspmCardsPresent && cardIndex)
        ? prefillTspmCard(tspmCards, cardIndex)
        : prefillManualCard(value);
}

const getCountry = countryCode =>
    some(([code]) => code === countryCode)(settings.COUNTRIES)
        ? countryCode
        : null;

export const retrieveDateEntities = (stringifiedDate) => {
    const [year, month, day] = stringifiedDate?.split('-') || [];
    return [Number(year), Number(month), Number(day)];
};

export const formatDate = (stringifiedDate) => {
    const [expiryYear, expiryMonth] = retrieveDateEntities(stringifiedDate);
    return `${pad(expiryMonth)}/${expiryYear}`;
}

export const getFormDataFromPassport = ({number, expirationDate, citizenship, issueCountry, dateOfBirth, nationality, gender, issueDate}) => {
    const [passportExpiryYear, passportExpiryMonth] = retrieveDateEntities(expirationDate);
    return {
        passportNumber: number,
        passportExpiryYear: Number(passportExpiryYear),
        passportExpiryMonth: Number(passportExpiryMonth),
        passportCountryOfIssue: getCountry(issueCountry),
        passportNationality: nationality || citizenship,
        passportDateOfBirth: dateOfBirth,
        passportGender: gender,
        passportDateOfIssue: issueDate,
    };
};

export const getFormDataFromVisa = (visa, placeOfBirth) => {
    const {
        number: visaNumber,
        'issue_date': issueDate,
        'expiration_date': expiryDate,
        'issued_for_country': visaCountry,
        'place_of_issue': visaIssuePlace,
        'place_of_birth': visaPlaceOfBirth
    } = visa;

    const [visaIssueYear, visaIssueMonth] = retrieveDateEntities(issueDate);
    const [visaExpiryYear, visaExpiryMonth] = retrieveDateEntities(expiryDate);

    return {
        visaNumber,
        visaIssueYear: Number(visaIssueYear),
        visaIssueMonth: Number(visaIssueMonth),
        visaExpiryYear: Number(visaExpiryYear),
        visaExpiryMonth: Number(visaExpiryMonth),
        visaCountry,
        visaIssuePlace,
        visaPlaceOfBirth: visaPlaceOfBirth || placeOfBirth,
    };
};

function prefillPassport({passportIndex, passports}) {
    if (!passports || !passports.length || passportIndex == null || !passports[passportIndex]) {
        return {};
    }

    const passport = passports[passportIndex];

    return {
        passportNumber: passport.number,
        passportExpiryDate: passport.expirationDate,
        passportCountryOfIssue: getCountry(passport.issueCountry),
        passportNationality: passport.nationality || passport.citizenship,
        passportIndex: passportIndex,
        passportGender: passport.gender,
        passportDateOfBirth: passport.dateOfBirth,
    };
}

function prefillVisa({visaIndex, 'visa_documents': visas}, state) {
    if (!visas || !visas.length || isNil(visaIndex)) {
        return {};
    }

    const [firstVisa] = visas;
    const visa = visas[visaIndex] || firstVisa;

    return {
        number: visa.number,
        issueDate: visa['issue_date'],
        issuedForCountry: getCountry(visa['issued_for_country']),
        placeOfIssue: visa['place_of_issue'],
        expirationDate: visa['expiration_date'],
        placeOfBirth: visa['place_of_birth'] || getPlaceOfBirth(state),
        visaIndex: visaIndex,
    };
}

function prefillVpaCardPool(value, state) {
    if (value.paid_by !== 'vpa_payment') {
        return {};
    }
    const [defaultPool] = getVpaCardPools(state) || [];

    return {
        'vpa_card_pool_id': get(defaultPool, 'vpa_client_id', null)
    };
}

export function prefill(state) {
    const pnr = getPnrProfile(state, 0);
    const checkoutData = getCheckoutData(state);
    const paymentOptions = getPaymentOptions(state) || ['card'];

    const {value} = getCheckoutForm(state);
    const prefilled = {
        phone: {},
        ...pnr && pickPnrFields(pnr),
        ...pick(checkoutData, CHECKOUT_DATA_FIELDS),
        'paid_by': find(['pnr', 'vpa_payment', 'card'], (item)=> paymentOptions.includes(item))
    };
    const crd = checkoutData?.crd || [];
    const filterCRD = crd.reduce(prefillCrd, prefilled);
    return {
        ...prefilled,
        ...filterCRD,
        ...prefillCreditCard({...prefilled, ...value}, state),
        ...prefillPassport({...prefilled, ...value}, state),
        ...prefillVpaCardPool({...prefilled, ...value}, state),
        ...{visa: prefillVisa({...prefilled, ...value}, state)},
    };
}

export function checkoutForm(state) {
    const {value, errors} = getCheckoutForm(state);
    return {
        value: {
            ...prefill(state),
            ...value,
        },
        errors,
    };
}

function expired() {
    return gettext('Please check your credit card expiration date. Credit card must not be expired.');
}

function isCardExpirationValid({year, month}) {
    // In case there is no data provided, let user enter it
    if (!year || !month) {
        return true;
    }
    // javascipt enumerates months from zero, so - 1
    const expirationDate = moment([year, month - 1])
        .endOf('month');
    const now = moment();
    return expirationDate.isAfter(now);
}

export function validateExpiration(value, dateOfTicket) {
    return isCardExpirationValid(value, dateOfTicket)
        ? null
        : expired();
}

function getTspmEmail(state) {
    const profile = getPnrProfile(state, 0);
    return profile ? profile['tspm_email'] : null;
}

// Check is there TSPM email and is it correct, because checkoutData has email without indication its source (tspm, pnr,
// pofile)
export function hasCorrectTspmEmail(state) {
    const tspmEmail = getTspmEmail(state);
    const checkoutData = getCheckoutData(state);
    const email = checkoutData && checkoutData.email;
    return Boolean(tspmEmail) && emailValidator()(tspmEmail) === null && tspmEmail === email;
}

export function getAllServiceGroupKeys(serviceGroups) {
    return serviceGroups.reduce(
        (serviceKeys, group) => serviceKeys
            .concat(group.options.map(option => option.serviceKey)),
        []
    );
}

function noErrorsBy(errors, fields = []) {
    if (!isObject(errors)) { return !errors; }
    return Object.entries(errors)
        .filter(([key]) => fields.includes(key))
        .every(([, error]) => error === null);
}

export function noVisaErrors(errors) {
    return noErrorsBy(errors, visaDraftFormFields);
}

export function noPassportErrors(errors) {
    return noErrorsBy(errors, manualPassportFormFields);
}

export function noIdCardErrors(errors) {
    return noErrorsBy(errors, idCardFormFields);
}

export function noSSRCodeErrors(errors) {
    return noErrorsBy(errors, ['ssrCodes']) && noErrorsBy(errors, ['ssrCodesSeparatedTickets']);
}

const createDateFromEntities = (year, month) => {
    return moment({year, month: month - 1, day: 1}).format('YYYY-MM-DD');
};

export const isPastDate = (year, month) => {
    const date = moment({year, month: month - 1, day: 1});
    return date.isBefore();
};

export const createPassportDraft = (passports, index) => {
    const passport = passports[index] || {};
    return getFormDataFromPassport(passport);
};

export const createPassportFromDraft = (passportDraft) => ({
    number: passportDraft.passportNumber,
    expirationDate: createDateFromEntities(
        passportDraft.passportExpiryYear,
        passportDraft.passportExpiryMonth
    ),
    citizenship: passportDraft.nationality,
    issueCountry: passportDraft.passportCountryOfIssue,
    dateOfBirth: passportDraft.passportDateOfBirth,
    gender: passportDraft.passportGender,
    issueDate: passportDraft.passportDateOfIssue,
});

export const prefillPassportFields = (formValue) => {
    const {passports = [], 'date_of_birth': dateOfBirth, gender, nationality} = formValue;

    return {
        passports: passports.map(
            passport => ({...passport, dateOfBirth, gender, nationality})
        ),
    }
}

export const prefillIdCardsFields = (formValue) => {
    const {idCards = [], 'date_of_birth': dateOfBirth, gender} = formValue;

    return {
        idCards: idCards.map(
            idCard => ({...idCard, idCardDateOfBirth: dateOfBirth, idCardGender: gender})
        ),
    }
}

export const createVisaDraft = (visas, index, placeOfBirth) => {
    const defaults = {'place_of_birth': placeOfBirth};
    const visa = visas[index] || defaults;
    return getFormDataFromVisa(visa, placeOfBirth);
};

export const createVisaFromDraft = (visaDraft) => ({
    'number': visaDraft.visaNumber,
    'issue_date': createDateFromEntities(visaDraft.visaIssueYear, visaDraft.visaIssueMonth),
    'expiration_date': createDateFromEntities(visaDraft.visaExpiryYear, visaDraft.visaExpiryMonth),
    'issued_for_country': visaDraft.visaCountry,
    'place_of_issue': visaDraft.visaIssuePlace,
    'place_of_birth': visaDraft.visaPlaceOfBirth,
});

export const createOrUpdate = (items, newItem, indexToModify) => {
    let update;
    if (indexToModify || indexToModify === 0) {
        update = [...items];
        update[indexToModify] = newItem;
    }
    else {
        update =  [...items, newItem];
    }
    return update;
};
