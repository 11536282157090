import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import TagList from 'midoffice/components/TagList';
import formatTags, {
    labels,
    namedDate,
    simpleValue,
} from 'midoffice/helpers/formatTags';
import {clearFilterTags, removeFilterTag} from './actions';

const LABELS = {
    'search': simpleValue,
    'timestamp_min': namedDate(gettext('From')),
    'timestamp_max': namedDate(gettext('To')),
    'user': labels,
    'affected_user': labels,
};

@connect(null, {
    onRemove: removeFilterTag,
    onClear: clearFilterTags,
})
export default class Tags extends React.Component {
    static propTypes = {
        value: PropTypes.array.isRequired,
        onClear: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    render() {
        const {value, onClear, onRemove, ...ctx} = this.props;
        return (
            <TagList
                status={formatTags(value, LABELS, ctx)}
                onClear={onClear}
                onRemove={onRemove}
            />
        );
    }
}
